/* eslint-disable */
import PropTypes from 'prop-types';
// material
import { styled } from '@mui/material/styles';
import { Toolbar, Tooltip, IconButton, Typography, OutlinedInput, InputAdornment, TextField, MenuItem } from '@mui/material';
// component
import Iconify from '../../../components/Iconify';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { fr } from 'date-fns/locale';

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

// ----------------------------------------------------------------------

ObjectifListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
};

export default function ObjectifListToolbar({ numSelected,
  onFilterSpace, 
  filterSpace,
  onFilterMonth, 
  filterMonth,
  handleOpenPopupDeleteObjectifs }) {
  return (
    <RootStyle
      sx={{
        ...(numSelected > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter',
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography component="div" variant="subtitle1">
          {numSelected} selected
        </Typography>
      ) : (
        <>
        <SearchStyle
          value={filterSpace}
          onChange={onFilterSpace}
          placeholder="Rechercher point de vente"
          startAdornment={
            <InputAdornment position="start">
              <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
            </InputAdornment>
          }
          style={{marginLeft:'2%'}}
        />
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={fr}>
        <DatePicker
              value={filterMonth}
              onChange={(newValue) => {
                onFilterMonth(newValue);
              }}
              views={['month']}
              inputFormat="MMMM"
              label="Mois"
              renderInput={(params) => <TextField {...params} helperText={null} />}
            />
            </LocalizationProvider>
        </>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <Iconify icon="eva:trash-2-fill" onClick={handleOpenPopupDeleteObjectifs} />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            {/* <Iconify icon="ic:round-filter-list" /> */}
          </IconButton>
        </Tooltip>
      )}
    </RootStyle>
  );
}
