/* eslint-disable */
import { filter } from 'lodash';
import { useEffect, useState } from 'react';
// material
import {
  Card,
  Table,
  Stack,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Input,
} from '@mui/material';
// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import { jsonWebService } from 'src/infrastructure/web-service';
import { URL_WS } from 'src/constants';
import AlertConfirmDeleteCar from 'src/sections/@dashboard/vente/AlertConfirmDeleteCar';
import AlertConfirmDeleteCars from 'src/sections/@dashboard/vente/AlertConfirmDeleteCars';
import AddCarForm from 'src/sections/@dashboard/space/AddSpaceForm';
import { makeStyles } from '@mui/styles';

import VenteListHead from 'src/sections/@dashboard/vente/VenteListHead';
import moment from 'moment';
import SalesListToolbar from 'src/sections/@dashboard/vente/SalesListToolbar';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

// ----------------------------------------------------------------------

const useStyles = makeStyles({
  
  buttonExport: {
    backgroundColor: '#2F95CA',
    color: '#fff',
    border: '0',
    minWidth:'64px',
    padding:'6px 16px',
    borderRadius:'8px',
    fontFamily:'Public Sans,sans-serif',
    fontSize:'0.875rem',
    fontWeight:'700',
    cursor:'pointer',
    boxShadow:'0 8px 16px 0 rgb(47 149 202 / 24%)',
    lineHeight:'1.7142857142857142',
    '&:hover': {
      backgroundColor: '#2F95CA',
      color: '#fff',
      borderColor:'#2F95CA'
  },
  
},})

const TABLE_HEAD = [
  { id: 'article', label: 'Article', alignRight: false },
  { id: 'processor', label: 'Processeur', alignRight: false },
  { id: 'category', label: 'Catégorie', alignRight: false },
  { id: 'space', label: 'Point de vente', alignRight: false },
  { id: 'seller', label: 'Promoteur', alignRight: false },
  { id: 'quantity', label: 'Quantité', alignRight: false },
  { id: 'date', label: 'Date', alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilterEspace(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_vente) => _vente.espace?.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

function applySortFilterReference(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_vente) => _vente.article?.reference.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

function applySortFilterProcessor(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_vente) => _vente.article?.description.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function Sales() {

  const classes = useStyles()

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [espace, setEspace] = useState('');

  const [articleReference, setArticleReference] = useState('');

  const [articleProcessor, setArticleProcessor] = useState('');

  const [venteList, setVenteList] = useState([]);

  const [ filtredVente, setFiltredVente] = useState([]);

  const [ categoryFilterValue, setCategoryFilterValue] = useState('Toutes les catégories');

  const [ monthValue, setMonthValue ] = useState(null)

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [editableRow, setEditableRow] = useState(undefined);

  const [openPopupDeleteCar, setOpenPopupDeleteCar] = useState(false);

  const [openPopupDeleteCars, setOpenPopupDeleteCars] = useState(false);

  const [carToDelete, setCarToDelete] = useState('');

  const [showAddCarForm, setShowAddCarForm] = useState(false);

  const openAddCarForm = () => {
    setShowAddCarForm(true);
  };

  const handleOpenPopupDeleteCar = (row) => {
    setCarToDelete(row)
    setOpenPopupDeleteCar(true);
  };

  const handleOpenPopupDeleteCars = (row) => {
    setOpenPopupDeleteCars(true);
  };

  const editRow = (row) => {
    setEditableRow(row._id);
    setCarBrand(row.brand);
    setCarModel(row.model);
  };

  const canselEditRow = () => {
    setEditableRow(undefined);
  };

  const handleUpdate = () => {
    // let newArr = [...carList];
    // var index = newArr.findIndex((item) => item._id === editableRow);
    // newArr[index].registrationNb = carMatricule;
    // newArr[index].brand = carBrand;
    // newArr[index].model = carModel;
    // setEditableRow(undefined);
    let data = {
    };
    // console.log('data :', data);
    jsonWebService
      .post(`${URL_WS}/vehicule/update/${editableRow}`, data)
      .then((response) => {
        setEditableRow(undefined);
        getVehicules()
      })
      .catch((err) => { });
  };

  const handleFilterByEspace = (event) => {
    setEspace(event.target.value);
  };

  const handleFilterByReference = (event) => {
    setArticleReference(event.target.value);
  };

  const handleFilterByProcessor = (event) => {
    setArticleProcessor(event.target.value);
  };

  useEffect(() => {
    jsonWebService
      .get(`${URL_WS}/vente/getAll`)
      .then((response) => {
        console.log('ventes', response)
        setVenteList(response.data);
        setFiltredVente(response.data);
      })
      .catch((err) => { });
  }, []);

  useEffect(() => {
    let filtredTable = venteList
    if(categoryFilterValue !== 'Toutes les catégories'){
      filtredTable = filtredTable.filter( x => x.article?.classification.toLowerCase() === categoryFilterValue.toLocaleLowerCase())
    }
    if(monthValue !== null){
      filtredTable = filtredTable.filter(x => 
        new Date(moment(x.date).format().substring(0, 10)).getMonth() === monthValue.getMonth())
    }

    if(espace !== ''){
      filtredTable = applySortFilterEspace(filtredTable, getComparator(order, orderBy), espace);
    }

    if(articleReference !== ''){
      filtredTable = applySortFilterReference(filtredTable, getComparator(order, orderBy), articleReference);
    }

    if(articleProcessor !== ''){
      filtredTable = applySortFilterProcessor(filtredTable, getComparator(order, orderBy), articleProcessor);
    }
    setFiltredVente(filtredTable)
    
  }, [categoryFilterValue, monthValue, espace, articleReference, articleProcessor]);

  const getVehicules = () => {
    jsonWebService
      .get(`${URL_WS}/vehicule`)
      .then((response) => {
        setVenteList(response.data);
      })
      .catch((err) => { });
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = venteList.map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, label) => {
    const selectedIndex = selected.indexOf(label);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, label);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const changeCategory = (e) => {
    setCategoryFilterValue(e.target.value)
  }

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - venteList.length) : 0;

  const tableToExport = <div style={{display:'none'}}>
    <Scrollbar>
    <TableContainer sx={{ minWidth: 800 }}>
              <Table id="table-to-xls">
                <VenteListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={venteList.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filtredVente.map((row) => (
                        <tr
                          hover
                          key={row._id}
                          tabIndex={-1}
                        >
                          <td>{row.article?.reference} </td>
                          <td>{row.article?.description}</td>
                          <td>{row.article?.classification}</td>
                          <td >{row.espace?.name}</td>
                          <td >{row.user.firstName} {row.user.lastName}</td>
                          <td >{row.number}</td>
                          <td >{moment(row.date).format().substring(0, 10)}</td>
                        </tr>
                    
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            </Scrollbar>
  </div>

  return (
    <Page title="Sales">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Ventes
          </Typography>
          <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className={classes.buttonExport}
                    table="table-to-xls"
                    filename="tablexls"
                    sheet="tablexls"
                    buttonText="Exporter"/>
          {/* <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
            Exporter
          </Button> */}
        </Stack>

        <Card>
            <SalesListToolbar
            categoryFilterValue = {categoryFilterValue} 
            onChangeCategory= {changeCategory}
            monthValue= {monthValue}
            onChangeMonthValue= {setMonthValue}
            numSelected={selected.length}
            filterEspace= {espace}
            onFilterEspace={handleFilterByEspace} 
            filterArticleReference={articleReference}
            onFilterReference={handleFilterByReference} 
            filterArticleProcessor= {articleProcessor}
            onFilterProcessor= {handleFilterByProcessor} 
            handleOpenPopupDeleteCars={handleOpenPopupDeleteCars} 
            />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <VenteListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={venteList.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                  useCheckBox
                />
                <TableBody>
                  {filtredVente.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const { _id, article, date, number, user, espace } = row;
                    const isItemSelected = selected.indexOf(_id) !== -1;
                    if (editableRow === row._id && row._id) {
                      return (
                        <TableRow
                          hover
                          key={_id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, name)} />
                          </TableCell>
                          <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              {/* <Avatar alt={name} src={avatarUrl} /> */}
                              <Input type="text" required="true" value={carMatricule} onChange={(e) => setCarMatricule(e.target.value)} />
                            </Stack>
                          </TableCell>
                          <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              {/* <Avatar alt={name} src={avatarUrl} /> */}
                              <Input type="text" required="true" value={carBrand} onChange={(e) => setCarBrand(e.target.value)} />
                            </Stack>
                          </TableCell>
                          <TableCell align="left">
                            <Input type="text" required="true" value={carModel} onChange={(e) => setCarModel(e.target.value)} />
                          </TableCell>
                          <TableCell align="left">
                            <Input type="text" required="true" value={carModel} onChange={(e) => setCarModel(e.target.value)} />
                          </TableCell>
                          <TableCell align="left">
                            <Iconify
                              icon="eva:save-outline"
                              width={20}
                              height={20}
                              color="darkBlue"
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                handleUpdate();
                              }}
                            />
                            <Iconify
                              icon="eva:close-circle-outline"
                              width={20}
                              height={20}
                              color="red"
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                canselEditRow();
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    }
                    else {
                      return (
                        <TableRow
                          hover
                          key={_id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, _id)} />
                          </TableCell>
                          <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              {/* <Avatar alt={name} src={avatarUrl} /> */}
                              <Typography variant="subtitle2" noWrap>
                                {article?.reference}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              {/* <Avatar alt={name} src={avatarUrl} /> */}
                              <Typography variant="subtitle2" noWrap>
                                {article?.description}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              {/* <Avatar alt={name} src={avatarUrl} /> */}
                              <Typography variant="subtitle2" noWrap>
                                {article?.classification}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">{espace?.name}</TableCell>
                          <TableCell align="left">{user.firstName} {user.lastName}</TableCell>
                          <TableCell align="left">{number}</TableCell>
                          <TableCell align="left">{moment(date).format().substring(0, 10)}</TableCell>
                          
                        </TableRow>
                      );
                    }
                  })}
                </TableBody>

                {/* {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterMatricule} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )} */}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[10, 20, 30]}
            component="div"
            count={venteList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          
        </Card>
        {tableToExport}
        <AlertConfirmDeleteCar
          openPopupDeleteCar={openPopupDeleteCar}
          setOpenPopupDeleteCar={setOpenPopupDeleteCar}
          carToDelete={carToDelete}
          getVehicules={getVehicules}
        />
        <AlertConfirmDeleteCars
          openPopupDeleteCars={openPopupDeleteCars}
          setOpenPopupDeleteCars={setOpenPopupDeleteCars}
          selectedCars={selected}
          getVehicules={getVehicules}
          setSelected={setSelected}
        />
        <AddCarForm
          showAddCarForm={showAddCarForm}
          setShowAddCarForm={setShowAddCarForm}
          getVehicules={getVehicules}
        />
      </Container>
    </Page>
  );
}
