/* eslint-disable */
import * as React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { createTheme, ThemeProvider, Link, styled, Typography } from '@mui/material';
import './style.css';
import { Box } from '@mui/system';
import BlogAddSpaceCard from '../blog/BlogAddSpaceCard';
import { Player } from '@lottiefiles/react-lottie-player';
import { URL_IMG, URL_WS } from 'src/constants';

// import Slide from '@mui/material/Slide';

const theme = createTheme({
  overrides: {
    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(0,0,0,0.2)',
      },
    },
  },
});

const TitleStyle = styled(Link)({
  overflow: 'hidden',
  WebkitLineClamp: 2,
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  fontWeight: 'bold',
});
export default function AddSpaceForm(props) {
  const { showAddSpaceForm, setShowAddSpaceForm, getSpaces } = props;

  const handleClose = () => {
    setShowAddSpaceForm(false);
  };

  return (
    <div>
      <ThemeProvider theme={theme}>
        <Dialog
          theme={theme}
          open={showAddSpaceForm}
          // TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          fullWidth
          maxWidth="lg"
        >
          <DialogTitle>
            {/* <TitleStyle to="#" color="inherit" variant="subtitle2" underline="none">
              Ajouter une nouvelle commande
            </TitleStyle> */}
          </DialogTitle>
          <DialogContent>
            <Box>
            <Player
        src= {`${URL_IMG}/lottieFiles/add_space.json`}
        style={{width:'30%'}}
        className="player"
        loop
        autoplay
      />
              
            </Box>

            <div style={{ textAlign: 'center' }}>
              <Typography variant="h6" color={'#2F95CA'} sx={{ marginBottom: 5, marginLeft: -7 }}>
                Ajouter un nouveau point de vente
              </Typography>
              <BlogAddSpaceCard
                setShowAddSpaceForm={setShowAddSpaceForm}
                handleClose={handleClose}
                getSpaces= {e => getSpaces()}
              />
            </div>
          </DialogContent>
        </Dialog>
      </ThemeProvider>
    </div>
  );
}
AddSpaceForm.propTypes = {
  open: PropTypes.object.isRequired,
  setOpen: PropTypes.object.isRequired,
};
