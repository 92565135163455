/* eslint-disable */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

// material
import { styled } from '@mui/material/styles';
import {
  Box,
  Link,
  Card,
  Grid,
  Typography,
  CardContent,
  CardActions,
  Stack,
  Input,
  Autocomplete,
  createFilterOptions,
  TextField,
  Checkbox,
  Button,
  InputLabel,
  FormControl,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

// utils
import Iconify from '../../../components/Iconify';
import { jsonWebService } from 'src/infrastructure/web-service';
import { URL_WS } from 'src/constants';
import * as eventsService from 'src/infrastructure/sub-pub-events/eventsSystem';
import { NOTIFICATION_TOAST_EVENT } from "src/infrastructure/sub-pub-events/eventTypes";
import Translation from 'src/Translation';
// import { fShortenNumber } from '../../../utils/formatNumber';
// //
// import SvgIconStyle from '../../../components/SvgIconStyle';
// import Iconify from '../../../components/Iconify';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

BlogAddUserCard.propTypes = {
  item: PropTypes.object.isRequired,
  index: PropTypes.number,
};

const useStyles = makeStyles({
  
  button: {
    backgroundColor: '#2F95CA',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#2F95CA',
      color: '#fff',
      borderColor:'#2F95CA'
  },
},
buttonOutlined: {
  backgroundColor: '#fff',
    color: '#2F95CA',
    borderColor:'#2F95CA',
    '&:hover': {
      backgroundColor: '#fff',
      color: '#2F95CA',
      borderColor:'#2F95CA'
  },
}})

export default function BlogAddUserCard({ setShowAddUserForm, handleClose, getUsers }) {
  const classes = useStyles()
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [space, setSpace] = useState('');
  const [password, setPassword] = useState('');
  const [listSpace, setListSpace] = useState([]);
  
  const [disableAddButton, setDisableAddButton] = useState(false);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    let list = []
    jsonWebService
      .get(`${URL_WS}/espace/getAll`)
      .then((response) => {
        response.data?.map(r => {
          let esp = { label: r.name , value : r._id }
          list.push(esp)
        })
        setListSpace(list)
      })
      .catch((err) => {});
  }, []);

  const AddNewUser = () => {
    let data = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      password: password,
      telephone: phone,
      espace: space.value,
    };
    
    setDisableAddButton(true)

    jsonWebService
      .post(`${URL_WS}/user/add`, data)
      .then((response) => {

        setFirstName('');
        setLastName('');
        setEmail('');
        setPhone('');
        setSpace('');
        setPassword('');
        setDisableAddButton(false)
        setShowAddUserForm(false);
        eventsService.publish(NOTIFICATION_TOAST_EVENT, { toastMessage: <Translation message= {response.message} />, variant: response.status });
        getUsers();
        setAlert({ open: true, type: 'success', message: 'Commande ajoutée avec succès' });
      })
      .catch((err) => {});
  };

  return (
    <Grid item md="12">
      {/* <Card sx={{ position: 'relative' }}> */}
      <>
        <div className="ml-5">
          

          <Grid container spacing={2} sx={{ marginTop: 1 }}>
            <Grid item xs={4}>
              <div
                style={{
                  color: '#2f4858',
                  display: 'flex',
                }}
              >
                <Iconify icon="ant-design:user-outlined" sx={{ width: 16, height: 16, mr: 1.5 }} />

                <Input
                  type="text"
                  value={lastName}
                  onChange={(e) => {
                    setLastName(e.target.value);
                  }}
                  placeholder="Nom"
                />
              </div>
            </Grid>
            <Grid item xs={4}>
              <div
                style={{
                  color: '#2f4858',
                  display: 'flex',
                }}
              >
                <Iconify icon="ant-design:user-outlined" sx={{ width: 16, height: 16, mr: 1.5 }} />

                <Input
                  type="text"
                  value={firstName}
                  onChange={(e) => {
                    setFirstName(e.target.value);
                  }}
                  placeholder="Prenom"
                />
              </div>
            </Grid>
            <Grid item xs={4}>
              <div
                style={{
                  color: '#2f4858',
                  display: 'flex',
                }}
              >
                <Iconify icon="cib:mail-ru" sx={{ width: 16, height: 16, mr: 1.5 }} />

                <Input
                  type="text"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  placeholder="Email"
                />
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ marginTop: 1 }}>
            <Grid item xs={4}>
             
            <div
                style={{
                  color: '#2f4858',
                  display: 'flex',
                }}
              >
                <Iconify icon="akar-icons:phone" sx={{ width: 16, height: 16, mr: 1.5 }} />

                <Input
                  type="text"
                  value={phone}
                  onChange={(e) => {
                    setPhone(e.target.value);
                  }}
                  placeholder="Phone"
                />
              </div>
            </Grid>
            <Grid item xs={4}>
              <div
                style={{
                  color: '#2f4858',
                  display: 'flex',
                }}
              >
                <Iconify icon="material-symbols:store-rounded" sx={{ width: 16, height: 16, mr: 1.5 }} />

                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={listSpace}
                  value={space}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      size="small"
                      sx={{ width: '200px' }}
                      placeholder="Point de vente"
                    />
                  )}
                  onChange={(event, newValue) => {
                    setSpace(newValue)
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={4}>
              <div
                style={{
                  color: '#2f4858',
                  display: 'flex',
                }}
              >
                <Iconify icon="mdi:password" sx={{ width: 16, height: 16, mr: 1.5 }} />

                <Input
                  type="text"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  placeholder="Password"
                />
              </div>
            </Grid>
          </Grid>
        </div>
       
      </>
      <Button
        variant="contained"
        className={classes.button}
        sx={{ marginTop: '1%', marginBottom: '1%' }}
        onClick={() => {
          AddNewUser();
        }}
        disabled={disableAddButton}
      >
        Ajouter
      </Button>
      <Button className= {classes.buttonOutlined} variant="outlined" sx={{ marginTop: '1%', marginBottom: '1%', marginLeft: '1%' }} onClick={handleClose}>
        Annuler
      </Button>
      {/* </Card> */}
    </Grid>
  );
}
