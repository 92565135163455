module.exports = {
  
  URL_WS: 'https://backend.pushandpull.workpoint.tn',
   URL_IMG: 'https://pushandpull.workpoint.tn',
  // URL_WS: 'http://localhost:5001',
 // URL_WS: 'http://51.210.242.227:5006',
 // URL_IMG: 'http://localhost:3000',

  // LOGOUT_WS: '/authweb/logout',
};
