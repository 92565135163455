/* eslint-disable */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

// material
import { styled } from '@mui/material/styles';
import {
  Grid,
  Input,
  Button,
  TextField,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

// utils
import Iconify from '../../../components/Iconify';
import { jsonWebService } from 'src/infrastructure/web-service';
import * as eventsService from 'src/infrastructure/sub-pub-events/eventsSystem';
import { NOTIFICATION_TOAST_EVENT } from 'src/infrastructure/sub-pub-events/eventTypes';
import { URL_WS } from 'src/constants';
import Translation from 'src/Translation';// import { fShortenNumber } from '../../../utils/formatNumber';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
// //
// import SvgIconStyle from '../../../components/SvgIconStyle';
// import Iconify from '../../../components/Iconify';

// ----------------------------------------------------------------------


const useStyles = makeStyles({
  
  button: {
    backgroundColor: '#2F95CA',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#2F95CA',
      color: '#fff',
      borderColor:'#2F95CA'
  },
},
buttonOutlined: {
  backgroundColor: '#fff',
    color: '#2F95CA',
    borderColor:'#2F95CA',
    '&:hover': {
      backgroundColor: '#fff',
      color: '#2F95CA',
      borderColor:'#2F95CA'
  },
}})
// ----------------------------------------------------------------------

BlogAddSpaceCard.propTypes = {
  item: PropTypes.object.isRequired,
  index: PropTypes.number,
};


export default function BlogAddSpaceCard({ setShowAddSpaceForm, handleClose, getSpaces }) {
  const classes = useStyles()
  const [name, setName] = useState('');
  const [adress, setAdress] = useState('');
  const [longitude, setLongitude] = useState('');
  const [latitude, setLatitude] = useState('');
  const [startHour, setStartHour] = useState(null);
  const [disableAddButton, setDisableAddButton] = useState(false);

  const AddNewSpace = () => {
    setDisableAddButton(true)
    let data = {
      name: name,
      longitude: longitude,
      latitude: latitude,
      address: adress,
      startingHour: `${new Date(startHour).getHours()} : ${new Date(startHour).getMinutes()}`
    };

    jsonWebService
      .post(`${URL_WS}/espace/add`, data)
      .then((response) => {
        setAdress('');
        setLongitude('');
        setLatitude('');
        setName('');
        setStartHour('');
        setShowAddSpaceForm(false);
        setDisableAddButton(false)
        eventsService.publish(NOTIFICATION_TOAST_EVENT, { toastMessage: <Translation message= {response.message} />, variant: response.status });
        getSpaces();
      })
      .catch((err) => {});
  };

  return (
    <Grid item md="12">
      {/* <Card sx={{ position: 'relative' }}> */}
      <>
        <div className="ml-5">
          <Grid container spacing={2} sx={{ marginTop: 1 }}>
            <Grid item xs={4}>
              <div
                style={{
                  color: '#2f4858',
                  display: 'flex',
                }}
              >
                <Iconify icon="ant-design:user-outlined" sx={{ width: 16, height: 16, mr: 1.5 }} />

                <Input
                  type="text"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  placeholder="Nom"
                />
              </div>
            </Grid>
            <Grid item xs={4}>
              <div
                style={{
                  color: '#2f4858',
                  display: 'flex',
                }}
              >
                <Iconify icon="mdi:office-building-marker" sx={{ width: 25, height: 25, mr: 1.5 }} />

                <Input
                  type="text"
                  value={adress}
                  onChange={(e) => {
                    setAdress(e.target.value);
                  }}
                  placeholder="Adresse"
                />
              </div>
            </Grid>
            <Grid item xs={4}>
              <div
                style={{
                  color: '#2f4858',
                  display: 'flex',
                }}
              >
                <Iconify icon="mdi:longitude" sx={{ width: 25, height: 25, mr: 1.5 }} />

                <Input
                  type="text"
                  value={longitude}
                  onChange={(e) => {
                    setLongitude(e.target.value);
                  }}
                  placeholder="Longitude"
                />
              </div>
            </Grid>
            <Grid item xs={4}>
              <div
                style={{
                  color: '#2f4858',
                  display: 'flex',
                }}
              >
                <Iconify icon="tabler:world-latitude" sx={{ width: 25, height: 25, mr: 1.5 }} />

                <Input
                  type="text"
                  value={latitude}
                  onChange={(e) => {
                    setLatitude(e.target.value);
                  }}
                  placeholder="Latitude"
                />
              </div>
            </Grid>
            <Grid item xs={4}>
              <div
                style={{
                  color: '#2f4858',
                  display: 'flex',
                }}
              >
                {/* <Iconify icon="tabler:clock-hour-8" sx={{ width: 25, height: 25, mr: 1.5 }} /> */}

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker
                    label="Horaire de démarrage"
                    value={startHour}
                    onChange={(newValue) => {
                      setStartHour(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} helperText={null} />}
                  />
                </LocalizationProvider>
              </div>
            </Grid>
          </Grid>
        </div>
      </>
      <Button
        variant="contained"
        sx={{ marginTop: '1%', marginBottom: '1%' }}
        onClick={() => {
          AddNewSpace();
        }}
        disabled={disableAddButton}
        className={classes.button}
      >
        Ajouter
      </Button>
      <Button className= {classes.buttonOutlined} variant="outlined" sx={{ marginTop: '1%', marginBottom: '1%', marginLeft: '1%' }} onClick={handleClose}>
        Annuler
      </Button>
      {/* </Card> */}
    </Grid>
  );
}
