/* eslint-disable */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

// material
import { styled } from '@mui/material/styles';
import {
  Grid,
  Input,
  Button,
  Autocomplete,
  TextField,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

// utils
import Iconify from '../../../components/Iconify';
import { jsonWebService } from 'src/infrastructure/web-service';
import * as eventsService from 'src/infrastructure/sub-pub-events/eventsSystem';
import { NOTIFICATION_TOAST_EVENT } from 'src/infrastructure/sub-pub-events/eventTypes';
import { URL_WS } from 'src/constants';
import Translation from 'src/Translation';// import { fShortenNumber } from '../../../utils/formatNumber';
// //
// import SvgIconStyle from '../../../components/SvgIconStyle';
// import Iconify from '../../../components/Iconify';

// ----------------------------------------------------------------------


const useStyles = makeStyles({
  
  button: {
    backgroundColor: '#2F95CA',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#2F95CA',
      color: '#fff',
      borderColor:'#2F95CA'
  },
},
buttonOutlined: {
  backgroundColor: '#fff',
    color: '#2F95CA',
    borderColor:'#2F95CA',
    '&:hover': {
      backgroundColor: '#fff',
      color: '#2F95CA',
      borderColor:'#2F95CA'
  },
}})
// ----------------------------------------------------------------------

BlogAddArticleCard.propTypes = {
  item: PropTypes.object.isRequired,
  index: PropTypes.number,
};


export default function BlogAddArticleCard({ setShowAddArticleForm, handleClose, getArticles }) {
  const classes = useStyles()
  const [articleReference, setArticleReference] = useState('');
  const [articleProcessor, setArticleProcessor] = useState('');
  const [articlePrice, setArticlePrice] = useState('');
  const [ category, setCategory] = useState('');
  const [disableAddButton, setDisableAddButton] = useState(false);

  const listCategorie = [
    {
      label:'CONS-LOW', value:'CONS-LOW'
    },
    {
      label:'CONS-MID', value:'CONS-MID'
    },
    {
      label:'CONS-HIGH', value:'CONS-HIGH'
    },
    {
      label:'COM-LOW', value:'COM-low'
    },
    {
      label:'COM-MID', value:'COM-MID'
    },
    {
      label:'COM-HIGH', value:'COM-HIGH'
    }

  ]

  const AddNewArticle = () => {
    setDisableAddButton(true)
    let data = {
      reference: articleReference,
      description: articleProcessor,
      classification: category.value,
      currentPrice: articlePrice,
    };

    jsonWebService
      .post(`${URL_WS}/article/add`, data)
      .then((response) => {
        setArticleReference('');
        setArticleProcessor('');
        setCategory('');
        setArticlePrice('');
        setShowAddArticleForm(false);
        setDisableAddButton(false)
        eventsService.publish(NOTIFICATION_TOAST_EVENT, { toastMessage: <Translation message= {response.message} />, variant: response.status });
        getArticles();
      })
      .catch((err) => {});
  };

  return (
    <Grid item md="12">
      {/* <Card sx={{ position: 'relative' }}> */}
      <>
        <div className="ml-5">
          <Grid container spacing={2} sx={{ marginTop: 1 }}>
            <Grid item xs={4}>
              <div
                style={{
                  color: '#2f4858',
                  display: 'flex',
                }}
              >
                <Iconify icon="material-symbols:quick-reference" sx={{ width: 16, height: 16, mr: 1.5 }} />

                <Input
                  type="text"
                  value={articleReference}
                  onChange={(e) => {
                    setArticleReference(e.target.value);
                  }}
                  placeholder="Reference"
                />
              </div>
            </Grid>
            <Grid item xs={4}>
              <div
                style={{
                  color: '#2f4858',
                  display: 'flex',
                }}
              >
                <Iconify icon="material-symbols:description" sx={{ width: 25, height: 25, mr: 1.5 }} />

                <Input
                  type="text"
                  value={articleProcessor}
                  onChange={(e) => {
                    setArticleProcessor(e.target.value);
                  }}
                  placeholder="Processeur"
                />
              </div>
            </Grid>
            <Grid item xs={4}>
              <div
                style={{
                  color: '#2f4858',
                  display: 'flex',
                }}
              >
                <Iconify icon="material-symbols:category" sx={{ width: 16, height: 16, mr: 1.5 }} />

                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={listCategorie}
                  value={category}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      size="small"
                      sx={{ width: '200px' }}
                      placeholder="Catégorie"
                    />
                  )}
                  onChange={(event, newValue) => {
                    setCategory(newValue)
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={4}>
              <div
                style={{
                  color: '#2f4858',
                  display: 'flex',
                }}
              >
                <Iconify icon="mdi:dollar" sx={{ width: 25, height: 25, mr: 1.5 }} />

                <Input
                  type="text"
                  value={articlePrice}
                  onChange={(e) => {
                    setArticlePrice(e.target.value);
                  }}
                  placeholder="Prix"
                />
              </div>
            </Grid>
          </Grid>
        </div>
      </>
      <Button
        variant="contained"
        sx={{ marginTop: '1%', marginBottom: '1%' }}
        onClick={() => {
          AddNewArticle();
        }}
        disabled={disableAddButton}
        className={classes.button}
      >
        Ajouter
      </Button>
      <Button className= {classes.buttonOutlined} variant="outlined" sx={{ marginTop: '1%', marginBottom: '1%', marginLeft: '1%' }} onClick={handleClose}>
        Annuler
      </Button>
      {/* </Card> */}
    </Grid>
  );
}
