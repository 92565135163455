import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { createTheme, ThemeProvider, TextField } from '@mui/material';

const theme = createTheme({
  overrides: {
    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(0,0,0,0.2)',
      },
    },
  },
});

const DialogScreen = ({ openDialog, handleClose, handleSubmit, dialogValue, setDialogValue }) => {
  return (
    <ThemeProvider theme={theme}>
      <Dialog open={openDialog} onClose={handleClose}>
        <form onSubmit={handleSubmit}>
          {/* <DialogTitle>Add a new film</DialogTitle> */}
          <DialogContent>
            <DialogContentText>Vous ne trouvez pas le commercial dans la liste? Ajoutez-le svp!</DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              value={dialogValue.userName}
              onChange={(event) =>
                setDialogValue({
                  ...dialogValue,
                  userName: event.target.value,
                })
              }
              label="username"
              type="text"
              variant="standard"
            />
            <TextField
              margin="dense"
              id="name"
              value={dialogValue.phone}
              onChange={(event) =>
                setDialogValue({
                  ...dialogValue,
                  year: event.target.value,
                })
              }
              label="phone"
              type="number"
              variant="standard"
              sx={{ marginLeft: 2 }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Annuler</Button>
            <Button type="submit">Ajouter</Button>
          </DialogActions>
        </form>
      </Dialog>
    </ThemeProvider>
  );
};

export default DialogScreen;
