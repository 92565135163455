/* eslint-disable */
import React, { useEffect } from 'react';
import { filter } from 'lodash';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Grid,
} from '@mui/material';
// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../sections/@dashboard/user';
// mock
import USERLIST from '../_mock/client';
import VeilleCommCard from 'src/components/veilleCommCompenents/VeilleCommCard';
import { jsonWebService } from 'src/infrastructure/web-service';
import { URL_WS } from 'src/constants';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function VeilleComm() {

  const [ veilleCommList, setVeilleCommList ] = useState([])

  useEffect(() => {
    jsonWebService
      .get(`${URL_WS}/CommercialWatch/getAll`)
      .then((response) => {
        setVeilleCommList(response.data)
      })
      .catch((err) => { });
  }, []);

  return (
    <Page title="VeilleComm">
      <Container>
      <Grid container spacing={3}>
        {
          veilleCommList.map(veille => (
            <VeilleCommCard VeilleComm = {veille} />
          ))
        }
      </Grid>
      </Container>
    </Page>
  );
}
