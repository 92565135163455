/* eslint-disable */
import PropTypes from 'prop-types';

// @mui
import { styled } from '@mui/material/styles';
import { Autocomplete, InputAdornment, Popper, TextField } from '@mui/material';
// components
import Iconify from '../../../components/Iconify';

// ----------------------------------------------------------------------

const PopperStyle = styled((props) => <Popper placement="bottom-start" {...props} />)({
  width: '280px !important',
});

// ----------------------------------------------------------------------

BlogPostsSearch.propTypes = {
  data: PropTypes.array.isRequired,
};

export default function BlogPostsSearch({ data, setData, handleReset, setSearchFilter, setReferenceSearch, setClientSearch }) {
  return (
    <Autocomplete
      sx={{ width: 280 }}
      autoHighlight
      onChange={(event, newValue) => {
        if (newValue) {
          setSearchFilter(true)
          setData(data.filter((item) => item.reference === newValue.reference));
          setReferenceSearch(newValue.reference);
          setClientSearch(newValue.orderer.name);
        } else {
          handleReset();
        }
      }}
      popupIcon={null}
      PopperComponent={PopperStyle}
      options={data}
      getOptionLabel={(data) => data.reference}
      isOptionEqualToValue={(option, value) => option._id === value._id}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Chercher commande..."
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <Iconify icon={'eva:search-fill'} sx={{ ml: 1, width: 20, height: 20, color: 'text.disabled' }} />
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
}
