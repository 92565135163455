/* eslint-disable */
import PropTypes from 'prop-types';
// material
import { styled } from '@mui/material/styles';
import { Toolbar, Tooltip, IconButton, Typography, OutlinedInput, InputAdornment, TextField, MenuItem, Stack } from '@mui/material';
// component
import Iconify from '../../../components/Iconify';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { fr } from 'date-fns/locale';

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  // height: 120,
  
  padding: theme.spacing(0, 1, 0, 3),
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

// ----------------------------------------------------------------------

SpaceListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterMatricule: PropTypes.string,
  onFilterMatricule: PropTypes.func,
};

export default function SpaceListToolbar({ 
  numSelected, 
  filterEspace, 
  onFilterEspace, 

  categoryFilterValue,
  onChangeCategory,
  monthValue,
  onChangeMonthValue,
  filterArticleReference,
  onFilterReference,
  filterArticleProcessor,
  onFilterProcessor,

  handleOpenPopupDeleteSpaces }) {
  return (
    <RootStyle
    sx={{
      ...(numSelected > 0 && {
        color: 'primary.main',
        bgcolor: 'primary.lighter',
        height: 96,
        display: 'flex',
        justifyContent: 'space-between',
      }),
      ...(numSelected <=0 && {
        display:'contents',
        justifyContent: 'space-between',
        height: 96,
      })
    }}>
      {numSelected > 0 ? (
        <Typography component="div" variant="subtitle1">
          {numSelected} selected
        </Typography>
      ) : (
        <>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5} style={{marginTop:'2%'}}>
        <SearchStyle
          value={filterEspace}
          onChange={onFilterEspace}
          placeholder="Rechercher point de vente"
          startAdornment={
            <InputAdornment position="start">
              <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
            </InputAdornment>
          }
          style={{marginLeft:'2%'}}
        />
        {/* <SearchStyle
          value={filterArticleReference}
          onChange={onFilterReference}
          placeholder="Search article..."
          startAdornment={
            <InputAdornment position="start">
              <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
            </InputAdornment>
          }
        />
        <SearchStyle
          value={filterArticleProcessor}
          onChange={onFilterProcessor}
          placeholder="Search Processeur..."
          startAdornment={
            <InputAdornment position="start">
              <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
            </InputAdornment>
          }
          style={{marginRight:'2%'}}
        /> */}
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5} style={{marginTop:'-2%', marginBottom:'-2%'}}>
        {/* <TextField
              select
              size="small"
              value={categoryFilterValue}
              onChange={onChangeCategory}
              style={{marginLeft:'2%'}}
            >
              <MenuItem key='all' value='Toutes les catégories'>
              Toutes les catégories
              </MenuItem>
              <MenuItem key='HC' value='High commercial'>
              High commercial
              </MenuItem>
              <MenuItem key='MC' value='Medium commercial'>
              Medium commercial
              </MenuItem>
              <MenuItem key='LC' value='Low commercial'>
              Low commercial
              </MenuItem>
              <MenuItem key='HS' value='High SMB'>
              High SMB
              </MenuItem>
              <MenuItem key='MS' value='Medium SMB'>
              Medium SMB
              </MenuItem>
              <MenuItem key='LS' value='Low SMB'>
              Low SMB
              </MenuItem>
            </TextField>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={fr}>
              <DatePicker
                views={['month']}
                inputFormat="MMMM"
                label="Mois"
                renderInput={(params) => <TextField {...params} helperText={null} style={{marginRight:'2%'}} />}
                value={monthValue}
                onChange={(newValue) => {
                  onChangeMonthValue(newValue);
                }}
              />
            </LocalizationProvider> */}

           
            </Stack>
        </>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <Iconify icon="eva:trash-2-fill" onClick={handleOpenPopupDeleteSpaces} />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            {/* <Iconify icon="ic:round-filter-list" /> */}
          </IconButton>
        </Tooltip>
      )}
    </RootStyle>
  );
}
