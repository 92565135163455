import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Commands from './pages/Blog';
import User from './pages/User';
import Login from './pages/Login';
import NotFound from './pages/Page404';
import Register from './pages/Register';
import DashboardApp from './pages/DashboardApp';
import Articles from './pages/Articles';
import ResetPassword from './pages/ResetPassword';
import { ProtectedRoute } from './ProtectedRoute';
import Espace from './pages/Espace';
import Calendar from './pages/Calendar';
import Sales from './pages/Sales';
import Objectif from './pages/Objectif';
import BlogAddUserCard from './sections/@dashboard/blog/BlogAddUserCard';
import VeilleComm from './pages/VeilleComm';
import Locations from './pages/Locations';
import Commentaires from './pages/Commentaires';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/dashboard',
      element: <ProtectedRoute><DashboardLayout /></ProtectedRoute>,
      children: [
        { path: 'app', element: <ProtectedRoute><DashboardApp /></ProtectedRoute> },
        { path: 'user', element: <User /> },
        { path: 'space', element: <Espace /> },
        { path: 'articles', element: <Articles /> },
        { path: 'sales', element: <Sales /> },
        { path: 'calendar', element: <Calendar /> },
        { path: 'locations', element: <Locations /> },
        { path: 'objectif', element: <Objectif /> },
        { path: 'veilleComm', element: <VeilleComm /> },
        { path: 'commentaire', element: <Commentaires /> },
      ],
    },
    {
      path: 'login',
      element: <Login />,
    },
    {
      path: 'register',
      element: <Register />,
    },
    {
      path: 'resetPassword',
      element: <ResetPassword />,
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: <Navigate to="/login" /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);
}
