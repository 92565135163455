/* eslint-disable */
import { filter } from 'lodash';
import { useEffect, useState } from 'react';
// material
import {
  Card,
  Table,
  Stack,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Input,
  TextField,
  MenuItem,
} from '@mui/material';
// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import { jsonWebService } from 'src/infrastructure/web-service';
import { URL_WS } from 'src/constants';
import CarListHead from 'src/sections/@dashboard/vente/VenteListHead';
import AlertConfirmDeleteCars from 'src/sections/@dashboard/vente/AlertConfirmDeleteCars';
import SpaceListToolbar from 'src/sections/@dashboard/space/SpaceListToolbar';
import AddSpaceForm from 'src/sections/@dashboard/space/AddSpaceForm';
import * as eventsService from 'src/infrastructure/sub-pub-events/eventsSystem';
import { NOTIFICATION_TOAST_EVENT } from 'src/infrastructure/sub-pub-events/eventTypes';
import AlertConfirmDeleteSpace from 'src/sections/@dashboard/space/AlertConfirmDeleteSpace';
import AlertConfirmDeleteSpaces from 'src/sections/@dashboard/space/AlertConfirmDeleteSpaces';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Nom', alignRight: false },
  { id: 'adress', label: 'Adresse', alignRight: false },
  { id: 'longitude', label: 'Longitude', alignRight: false },
  { id: 'latitude', label: 'Latitude', alignRight: false },
  { id: 'startingHour', label: 'Horaire de démarrage', alignRight: false },
  { id: 'isActive', label: 'Actif / Non actif', alignRight: false },
  { id: 'action', label: 'Action', alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function Espace() {
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterSpace, setFilterSpace] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [spaceList, setSpaceList] = useState([]);

  const [spaceName, setSpaceName] = useState('');

  const [spaceNameFilter, setSpaceNameFilter] = useState('');

  const [spaceAdress, setSpaceAdress] = useState('');

  const [spaceLongitude, setSpaceLongitude] = useState('');

  const [spaceLatitude, setSpaceLatitude] = useState('');

  const [ startHour, setStartHour ] = useState(null);

  const [isSpaceActive, setIsSpaceActive] = useState(null);

  const [editableRow, setEditableRow] = useState(undefined);

  const [openPopupDeleteSpace, setOpenPopupDeleteSpace] = useState(false);

  const [openPopupDeleteSpaces, setOpenPopupDeleteSpaces] = useState(false);

  const [spaceToDelete, setSpaceToDelete] = useState('');

  const [showAddSpaceForm, setShowAddSpaceForm] = useState(false);

  const openAddSpaceForm = () => {
    setShowAddSpaceForm(true);
  };

  const handleOpenPopupDeleteSpace = (row) => {
    setSpaceToDelete(row)
    setOpenPopupDeleteSpace(true);
  };

  const handleOpenPopupDeleteSpaces = (row) => {
    setOpenPopupDeleteSpaces(true);
  };

  const editRow = (row) => {
    setEditableRow(row._id);
    setSpaceName(row.name);
    setSpaceAdress(row.address);
    setIsSpaceActive(row.isActive);
    setSpaceLongitude(row.longitude);
    setSpaceLatitude(row.latitude);
    setStartHour(`2022/02/28 ${row.startingHour}`)
    setIsSpaceActive(row.isActive ? true : false)
  };

  const canselEditRow = () => {
    setEditableRow(undefined);
  };

  const handleUpdate = () => {
    let data = {
      name: spaceName,
      longitude: spaceLongitude,
      latitude: spaceLatitude,
      address: spaceAdress,
      isActive: isSpaceActive,
      startingHour: `${new Date(startHour).getHours()}:${new Date(startHour).getMinutes()}`

    };
    // console.log('data :', data);
    jsonWebService
      .post(`${URL_WS}/espace/update/${editableRow}`, data)
      .then((response) => {
        setEditableRow(undefined);
        getSpaces()
        eventsService.publish(NOTIFICATION_TOAST_EVENT, { toastMessage: <Translation message= {response.message} />, variant: response.status });
      })
      .catch((err) => { });
  };

  useEffect(() => {
    jsonWebService
      .get(`${URL_WS}/espace/getAll`)
      .then((response) => {
        setSpaceList(response.data);
        setFilterSpace(response.data)
      })
      .catch((err) => {
      });
  }, []);

  useEffect(() => {
    let filtredTable = spaceList
    if (spaceNameFilter !== '') {
      filtredTable = filtredTable.filter(x => x.name.toLowerCase() === spaceNameFilter.toLocaleLowerCase())
    }
    setFilterSpace(filtredTable)

  }, [spaceNameFilter]);

  const getSpaces = () => {
    jsonWebService
      .get(`${URL_WS}/espace/getAll`)
      .then((response) => {
        setSpaceList(response.data);
      })
      .catch((err) => { });
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = spaceList.map((n) => n.registrationNb);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, label) => {
    const selectedIndex = selected.indexOf(label);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, label);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByEspace = (event) => {
    setSpaceNameFilter(event.target.value);
  };

  const onChangeSpaceStatus = (event) => {
    setIsSpaceActive(event.target.value)
  }

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - spaceList.length) : 0;

  const filteredSpaces = applySortFilter(spaceList, getComparator(order, orderBy), spaceNameFilter);

  const isUserNotFound = filteredSpaces.length === 0;

  return (
    <Page title="Space">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Point de vente
          </Typography>
          <Button variant="contained" onClick={openAddSpaceForm} startIcon={<Iconify icon="eva:plus-fill" />}>
            Nouveau point de vente
          </Button>
        </Stack>

        <Card>
          <SpaceListToolbar
            numSelected={selected.length}
            filterEspace={spaceNameFilter}
            onFilterEspace={handleFilterByEspace}
            handleOpenPopupDeleteSpaces={handleOpenPopupDeleteSpaces} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <CarListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={spaceList.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                  useCheckBox
                />
                <TableBody>
                  {filteredSpaces.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const { _id, name, address, isActive, longitude, latitude, startingHour } = row;
                    const isItemSelected = selected.indexOf(_id) !== -1;
                    if (editableRow === row._id && row._id) {
                      return (
                        <TableRow
                          hover
                          key={_id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, _id)} />
                          </TableCell>
                          <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              {/* <Avatar alt={name} src={avatarUrl} /> */}
                              <Input type="text" required="true" value={spaceName} onChange={(e) => setSpaceName(e.target.value)} />
                            </Stack>
                          </TableCell>
                          <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              {/* <Avatar alt={name} src={avatarUrl} /> */}
                              <Input type="text" required="true" value={spaceAdress} onChange={(e) => setSpaceAdress(e.target.value)} />
                            </Stack>
                          </TableCell>
                          <TableCell align="left">
                            <Input type="text" required="true" value={spaceLongitude} onChange={(e) => setSpaceLongitude(e.target.value)} />
                          </TableCell>
                          <TableCell align="left">
                            <Input type="text" required="true" value={spaceLatitude} onChange={(e) => setSpaceLatitude(e.target.value)} />
                          </TableCell>
                          <TableCell align="left">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <TimePicker
                               label="Horaire de démarrage"
                               value={startHour}
                               onChange={(newValue) => {
                                 setStartHour(newValue);
                                }}
                                renderInput={(params) => <TextField {...params} helperText={null} />}
                              />
                            </LocalizationProvider>
                          </TableCell>
                          
                          <TableCell align="left">
                            <TextField
                              select
                              size="small"
                              value={isSpaceActive}
                              onChange={onChangeSpaceStatus}
                            >
                              <MenuItem key='all' value={true}>
                                Actif
                              </MenuItem>
                              <MenuItem key='HC' value={false}>
                                Inactif
                              </MenuItem>
                            </TextField>
                            </TableCell>
                          <TableCell align="left">
                            <Iconify
                              icon="eva:save-outline"
                              width={20}
                              height={20}
                              color="darkBlue"
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                handleUpdate();
                              }}
                            />
                            <Iconify
                              icon="eva:close-circle-outline"
                              width={20}
                              height={20}
                              color="red"
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                canselEditRow();
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    }
                    else {
                      return (
                        <TableRow
                          hover
                          key={_id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, _id)} />
                          </TableCell>
                          <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              {/* <Avatar alt={name} src={avatarUrl} /> */}
                              <Typography variant="subtitle2" noWrap>
                                {name}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              {/* <Avatar alt={name} src={avatarUrl} /> */}
                              <Typography variant="subtitle2" noWrap>
                                {address}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">{longitude}</TableCell>
                          <TableCell align="left">{latitude}</TableCell>
                          <TableCell align="left">{startingHour}</TableCell>
                          <TableCell align="left">{isActive ? 'Actif' : 'Inactif'}</TableCell>
                          <TableCell align="left">
                            <Iconify
                              icon="eva:edit-2-outline"
                              width={20}
                              height={20}
                              color="gray"
                              style={{ cursor: 'pointer' }}
                            onClick={() => {
                              editRow(row);
                            }}
                            />
                            <Iconify
                              icon="eva:trash-2-outline"
                              width={20}
                              height={20}
                              color="gray"
                              style={{ cursor: 'pointer' }}
                            onClick={e => handleOpenPopupDeleteSpace(row)}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    }
                  })}
                </TableBody>

                {/* {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterMatricule} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )} */}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredSpaces.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
        <AlertConfirmDeleteSpace
          openPopupDeleteSpace={openPopupDeleteSpace}
          setOpenPopupDeleteSpace={setOpenPopupDeleteSpace}
          spaceToDelete={spaceToDelete}
          getSpaces={getSpaces}
        />
        <AlertConfirmDeleteSpaces
          openPopupDeleteSpaces={openPopupDeleteSpaces}
          setOpenPopupDeleteSpaces={setOpenPopupDeleteSpaces}
          selectedSpaces={selected}
          getSpaces={getSpaces}
          setSelected={setSelected}
        />
        <AddSpaceForm
          showAddSpaceForm={showAddSpaceForm}
          setShowAddSpaceForm={setShowAddSpaceForm}
          getSpaces={getSpaces}
        />
      </Container>
    </Page>
  );
}
