/* eslint-disable */
import * as React from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography, TextField, ToggleButtonGroup, ToggleButton } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
// components
import Page from '../components/Page';
import Iconify from '../components/Iconify';
// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
} from '../sections/@dashboard/app';

// ----------------------------------------------------------------------

export default function DashboardApp() {
  const theme = useTheme();
  const [value, setValue] = React.useState(null);
  const [filter, setFilter] = React.useState("weekly");

  const handleChange = (event, newValue) => {
    setFilter(newValue);
  };

  return (
    <Page title="Dashboard">
      <center>
        <div style={{ marginTop: '2%', marginBottom: '2%' }}>
        <ToggleButtonGroup color="primary" value={filter} exclusive onChange={handleChange} aria-label="Platform">
              {/* <ToggleButton value="general">Général</ToggleButton> */}
              <ToggleButton value="weekly">Par semaine</ToggleButton>
              <ToggleButton value="monthly">Par mois</ToggleButton>
            </ToggleButtonGroup>
        </div>
      </center>
      <Container maxWidth="xl">

      {/* {filter === 'general' && <iframe style={{background: '#F1F5F4',border: 'none',borderRadius: '2px',boxShadow: '0 2px 10px 0 rgba(70, 76, 79, .2)', width:'100%', height:'500px'}}  src={`https://charts.mongodb.com/charts-workforce-qndmi/embed/dashboards?id=ad5d49a5-3f8b-4c7e-b181-bdee99d532b3&theme=light&autoRefresh=true&maxDataAge=3600&showTitleAndDesc=false&scalingWidth=fixed&scalingHeight=fixed&filter={ $match : {vehicule: "TU 201"}`}>
      </iframe>} */}

      {filter === 'weekly' && <iframe id="myFrame"  allowFullScreen="true" style={{background: '#F1F5F4',border: 'none',borderRadius: '2px',boxShadow: '0 2px 10px 0 rgba(70, 76, 79, .2)', width:'100%', height:'500px'}}  src='https://charts.mongodb.com/charts-push---pull-nanxq/public/dashboards/f33ab0bd-3cf4-4765-ab68-710a3ba84f3c'>
      </iframe>}

      {filter === 'monthly' && <iframe id="myFrame"  allowFullScreen="true" style={{background: '#F1F5F4',border: 'none',borderRadius: '2px',boxShadow: '0 2px 10px 0 rgba(70, 76, 79, .2)', width:'100%', height:'500px'}}  src='https://charts.mongodb.com/charts-push---pull-nanxq/public/dashboards/647a2776-186d-4f37-85fc-e1cdb2e7fafa'>
      </iframe>}

      </Container>
    </Page>
  );
}
