/* eslint-disable */
import { filter } from 'lodash';
import React, { useEffect, useState } from 'react';
// material
import {
  Stack,
  Button,
  Container,
  Switch,
  Typography,
  TextField,
  CircularProgress,
  Autocomplete,
} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
// components
import Page from '../components/Page';
import Iconify from '../components/Iconify';
import { jsonWebService } from 'src/infrastructure/web-service';
import { URL_WS } from 'src/constants';
import { getCurrentUser } from 'src/services/user/current-user';
import 'mapbox-gl/dist/mapbox-gl.css';
import moment from 'moment';

// ----------------------------------------------------------------------
const useStyles = theme =>({
  
  buttonExport: {
    backgroundColor: '#2F95CA',
    color: '#fff',
    border: '0',
    minWidth:'64px',
    padding:'6px 16px',
    borderRadius:'8px',
    fontFamily:'Public Sans,sans-serif',
    fontSize:'0.875rem',
    fontWeight:'700',
    cursor:'pointer',
    boxShadow:'0 8px 16px 0 rgb(47 149 202 / 24%)',
    lineHeight:'1.7142857142857142',
    '&:hover': {
      backgroundColor: '#2F95CA',
      color: '#fff',
      borderColor:'#2F95CA'
  },
  
},})
let refDate = null
class Commentaires extends React.Component {
  state= {
    listUsers: [],
    selectedUser: null,
    discussion: null,
    messageToSend: '',
    loader: false,
    //websocket states
    userName: '',
    isLoggedIn: false,
    messages: [],
    disabled : false,
  }


  componentDidMount(){
    const { selectedUser } = this.state
    this.getAllUsers()
  }


  getAllUsers(){
    jsonWebService
    .get(`${URL_WS}/user/getAll`)
    .then((response) => {
      var res = response.data.map((user) => {
        return {
          ...user,
          label: `${user.firstName !== undefined ? user.firstName : ''} ${user.lastName !== undefined ? user.lastName : ''}`,
        };
      });
      this.setState({ listUsers : res })
    })
    .catch((err) => { });
  }

  changeUser(e){
    this.setState({ selectedUser : e})
    this.getUserDiscussion(e._id, true)
  }

  getUserDiscussion(userId, activateLoader){
    this.setState({ loader : activateLoader })
    jsonWebService
    .get(`${URL_WS}/Discussion/get/${userId}`)
    .then((response) => {
      this.setState({ discussion : response.data,
        loader : false,
        messageToSend : '',
        disabled: false
      })
      setTimeout(
        () => this.scrollToBottom(), 
        1500
      );
    })
    .catch((err) => { });
  }

  scrollToBottom(){
    let objDiv = document.getElementById("discussionDiv");
    objDiv.scrollTop = objDiv.scrollHeight;
  }

  changeMessageToSend(e){
    this.setState({ messageToSend : e.target.value })
  }

  sendMessage(){
    const { messageToSend, selectedUser, userName } =this.state
    this.setState({ disabled : true })
    let url = `${URL_WS}/Discussion/add`
    
    if(messageToSend !== ''){
      jsonWebService.post(url, {
        content: messageToSend,
        userDiscussion: selectedUser._id,
        Sender: getCurrentUser().id
      })
    .then((response) => {
      this.getUserDiscussion(getCurrentUser().id, false)      
    })
    .catch((err) => {
    });
    }
    
  }

  checkDay(date){
    if(moment(date).format('YYYY/MM/DD') === refDate){
      return false
    }
    else {
      refDate = moment(date).format('YYYY/MM/DD')
      return true
    }
  }

  render() {
    const { listUsers, selectedUser, messageToSend, discussion, loader, disabled } = this.state
    
    let admin = getCurrentUser().id

        return (
          <Page title="Commentaires" >
            <Container>
            {/* <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={listUsers}
            value={selectedUser?.label}
            onChange={(event, newValue) => {
              this.changeUser(newValue);
            }}
            // sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="Utilisateurs" />}
          /> */}
          <div style={{display:'flex', width:'100%'}}>
              <div style={{width:'80%'}}>
                <div id='discussionDiv' style={{ marginTop:'2%', maxHeight:'445px',minHeight:'445px', overflowY:'scroll'}}>
            {loader ? 
            <CircularProgress />
          :
          discussion?.length > 0 ?
          discussion.map(d => (
            <div style={{width:'100%', float:'right'}}>
              {this.checkDay(d.createdAt) &&
              <center>
                <div style={{fontSize:'small', color:'#a8b1c7'}}>
                  {moment(d.createdAt).format('YYYY/MM/DD')}
                </div>
                </center>
              }
            <div style={
              admin === d.Sender._id ? 
              {backgroundColor:'rgb(47, 149, 202)', color:'#fff', marginBlock:'1%', borderRadius:'12px', paddingLeft:'1%',paddingRight:'1%', width:'fit-content', float:'right'}
              :
              {backgroundColor:'#e4e6eb', color:'#000', marginBlock:'1%', borderRadius:'12px', paddingLeft:'1%',paddingRight:'1%', width:'fit-content'}}>
              <span style={{fontSize:'small', marginBottom:'-23%',  width:'fit-content'}}>{d.Sender.firstName} {d.Sender.lastName} {moment(d.createdAt).format("HH:mm")}</span><br/>
              {d.content}
            </div>
            </div>
          ))
          :
          <></>
          }
                </div>
                <div style={{ bottom:'0', position:'flex'}}>
          <TextField
          label='Commentaire'
          value={messageToSend}
          onChange= {e => this.changeMessageToSend(e)}
          style={{ width: '90%', marginTop: '1%'}}
          />
          <Iconify
          icon="material-symbols:send"
          onClick={e => !disabled ? this.sendMessage() : null}
          style={
            disabled ?
            { fontSize: '30', marginTop:'2%', marginLeft:'2%', cursor:'pointer', color:'#D3D3D3' }
            :
            { fontSize: '30', marginTop:'2%', marginLeft:'2%', cursor:'pointer', color:'rgb(47, 149, 202)' }
            } />
                </div>
              </div>
              <div style={{width:'20%', overflowY:'scroll', cursor:'pointer'}}>
                {
                  listUsers.map(u => (
                  <div className='contactList' onClick={e => this.changeUser(u)}>
                <div style={{backgroundColor:'rgb(47, 149, 202)', borderRadius:'50%', width:'23px', height:'23px'}}>
                  <div style={{textAlign:'center', marginBottom:'1%', color:'#fff'}}>
                    {u.label.charAt(0)}
                  </div>
                  </div>
                  <div className='contact' style={selectedUser?._id === u._id ? {backgroundColor:'#C8C4C3'}:{backgroundColor:'#f5f6f8'}}>
                  {u.label}
                  </div>
                  {/* <div style={{backgroundColor:'#AC1607', borderRadius:'50%', width:'23px', height:'23px', float:'right'}}>
                  <div style={{textAlign:'center', marginBottom:'1%', color:'#fff'}}>
                    7
                  </div>
                  </div> */}
                </div>
                  ))
                }
                
              </div>
          </div>
              </Container>
          </Page>
        );
    }
    
    
  }

export default withStyles(useStyles) (Commentaires)
