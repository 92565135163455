/* eslint-disable */
import * as React from 'react';
import { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {
  createTheme,
  ThemeProvider,
  Link,
  styled,
  Button,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
} from '@mui/material';
import './style.css';
import { userServices } from 'src/services/user';
import { getCurrentUser } from 'src/services/user/current-user';

// import Slide from '@mui/material/Slide';

const theme = createTheme({
  overrides: {
    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(0,0,0,0.2)',
      },
    },
  },
});

const TitleStyle = styled(Link)({
  // height: 44,
  overflow: 'hidden',
  WebkitLineClamp: 2,
  // display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  fontWeight: '600',
  marginTop:'5%',
  // width:'150px'
});
export default function ToggleQuestion(props) {
  const { toggleQuestion, onChangeToggle, resetValues } = props;
  const [ toggleValue, setToggleValue] = React.useState(toggleQuestion.response[0].value)
  const [reset, setReset] = React.useState(resetValues)

  useLayoutEffect(() => {
    setReset(true)
  }, [props.resetValues]);
  
  const changeToggle = (e, id) => {
    if(getCurrentUser().role === 'admin'){
      setReset(false)
      setToggleValue(e.target.value === 'Oui' ? 'Oui' : 'Non')
      onChangeToggle(e, toggleQuestion.response[0]?._id)
    }
    
  }

  // const Transition = React.forwardRef(function Transition(props, ref) {
  //   return <Slide direction="up" ref={ref} {...props} />;
  // });


  return (
    <>
      <div className="flex" style={{ marginLeft: '0%' }}>
        <div className="w-10">
          <TitleStyle to="#" color="inherit" variant="subtitle2" underline="none">
            {toggleQuestion.statement.value}
          </TitleStyle>
        </div>
        <ToggleButtonGroup color="primary" size="small" exclusive sx={{height:'25px'}} onChange={e => changeToggle(e, toggleQuestion._id)}>
          <ToggleButton
           value="Oui"
           style={ reset ?
            toggleQuestion.response[0]?.value === 'Oui' ? { outline: 'none', color: '#258053', backgroundColor: '#A5EAC9 '}
                :  
                { outline: 'none', backgroundColor:'#fff', color: 'grey' }
            :
            toggleValue === 'Oui' ? { outline: 'none', color: '#258053', backgroundColor: '#A5EAC9 '}
                :  
                { outline: 'none', backgroundColor:'#fff', color: 'grey' }}
           selected={reset ? 
                          toggleQuestion.response[0].value === 'Oui' ? 'Oui' : 'Non' 
                          : 
                          toggleValue === 'Oui' ? 'Oui' : 'Non'}>
            Oui
          </ToggleButton>
          <ToggleButton
          value="Non"
          style={ reset ?
            toggleQuestion.response[0]?.value === 'Non' ? { outline: 'none', backgroundColor:'#F9BCBB', color:'red' }
                :  
                { outline: 'none', backgroundColor:'#fff', color: 'grey' }
            :
            toggleValue === 'Non' ? { outline: 'none', backgroundColor:'#F9BCBB', color:'red' }
                :  
                { outline: 'none', backgroundColor:'#fff', color: 'grey' }}
          selected={reset ?
                          toggleQuestion.response[0].value === 'Non' ? 'Oui' : 'Non'
                          : 
                          toggleValue === 'Non' ? 'Oui' : 'Non'}>
            Non
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
    </>
  );
}
ToggleQuestion.propTypes = {
  rateQuestion: PropTypes.object.isRequired,
};


