/* eslint-disable */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

// material
import { styled } from '@mui/material/styles';
import {
  Box,
  Link,
  Card,
  Grid,
  Typography,
  CardContent,
  CardActions,
  Stack,
  Input,
  Autocomplete,
  createFilterOptions,
  TextField,
  Checkbox,
  Button,
  InputLabel,
  FormControl,
} from '@mui/material';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

// utils
import { fDate } from '../../../utils/formatTime';
import Label from '../../../components/Label';
import Iconify from '../../../components/Iconify';
import Article from '../articles/Article';
import AlertDialogSlide from '../articles/AlertDialogSlide';
import AlertConfirmDeleteCmd from '../articles/AlertConfirmDeleteCmd';
import DialogScreen from './DialogScreen';
import { jsonWebService } from 'src/infrastructure/web-service';
import * as eventsService from 'src/infrastructure/sub-pub-events/eventsSystem';
import { NOTIFICATION_TOAST_EVENT } from 'src/infrastructure/sub-pub-events/eventTypes';
import { URL_WS } from 'src/constants';
import AddArticles from '../articles/AddArticles';
import Translation from '../../../Translation';
// import { fShortenNumber } from '../../../utils/formatNumber';
// //
// import SvgIconStyle from '../../../components/SvgIconStyle';
// import Iconify from '../../../components/Iconify';

// ----------------------------------------------------------------------

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const TitleStyle = styled(Link)({
  height: 35,
  overflow: 'hidden',
  WebkitLineClamp: 2,
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
});

const InfoStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  // justifyContent: 'flex-end',
  marginTop: theme.spacing(4),
  color: theme.palette.text.disabled,
}));

const ExpandMore = styled((props) => {
  const { ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));
// ----------------------------------------------------------------------

BlogAddCommandCard.propTypes = {
  item: PropTypes.object.isRequired,
  index: PropTypes.number,
};

const filter = createFilterOptions();

export default function BlogAddCommandCard({ index, commercialList, camionList, setShowAddCommandForm, handleClose }) {
  const [reference, setReference] = useState('');
  const [targetDate, setTargetDate] = useState(new Date().toISOString().substring(0, 10));
  const [vehicule, setVehicule] = useState('');
  const [owner, setOwner] = useState(null);
  const [ordererName, setOrdererName] = useState('');
  const [ordererPhone, setOrdererPhone] = useState('');
  const [status, setStatus] = useState('new');
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [listArticle, setListArticle] = useState([]);
  const [alert, setAlert] = useState({ open: false, type: '', message: '' });
  const [disableAddButton, setDisableAddButton] = useState(false);

  let color;

  if (status === 'delivered') {
    color = 'success';
  } else if (status === 'cancelled') {
    color = 'error';
  } else if (status === 'new') {
    color = 'success';
  } else {
    color = 'warning';
  }

  const AddNewCommand = () => {
    if (reference.length == 0) {
      setAlert({ open: true, type: 'warning', message: 'Réference est obligatoire' });
      return;
    }

    if (targetDate.length == 0) {
      setAlert({ open: true, type: 'warning', message: 'Date est obligatoire' });
      return;
    }
    setDisableAddButton(true)
    let data = {
      reference: reference,
      // targetDate: `${new Date(targetDate).getFullYear()}-${new Date(targetDate).getMonth() + 1}-${new Date(
      //   targetDate
      // ).getDate()}`,
      targetDate: targetDate,
      vehicule: vehicule?._id,
      owner: owner?.userName,
      orderer: {
        name: ordererName,
        phone: ordererPhone,
      },
      address: {
        address1: address1,
        address2: address2,
      },
      items: listArticle,
    };
    jsonWebService
      .post(`${URL_WS}/order/add`, data)
      .then((response) => {
        setReference('');
        setTargetDate(new Date().toISOString().substring(0, 10));
        setVehicule('');
        setOwner('');
        setOrdererName('');
        setOrdererPhone('');
        setAddress1('');
        setAddress2('');
        setListArticle([]);
        setDisableAddButton(false);
        setShowAddCommandForm(false);
        setAlert({ open: true, type: 'success', message: 'Commande ajoutée avec succès' });
      })
      .catch((err) => {
        setAlert({ open: true, type: 'error', message: <Translation message={err.message} /> });
      });
  };

  const handleChangeOwner = (event, newValue) => {
    setOwner(newValue);
  };

  const CloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setAlert({ open: false, type: '', message: '' });
  };

  return (
    <Grid item md="12">
      {/* <Card sx={{ position: 'relative' }}> */}
      <>
        <div className="ml-5">
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <div
                style={{
                  color: '#2f4858',
                  display: 'flex',
                }}
              >
                <Iconify icon="eva:calendar-outline" sx={{ width: 16, height: 16, mr: 1.5 }} />

                <Input
                  type="date"
                  onChange={(e) => setTargetDate(e.target.value)}
                  value={targetDate}
                  required
                  // value={
                  //   (new Date(targetDate).getMonth() + 1).toString().length < 2
                  //     ? new Date(targetDate).getDate().toString().length < 2
                  //       ? `${new Date(targetDate).getFullYear()}-0${new Date(targetDate).getMonth() + 1}-0${new Date(
                  //           targetDate
                  //         ).getDate()}`
                  //       : `${new Date(targetDate).getFullYear()}-0${new Date(targetDate).getMonth() + 1}-${new Date(
                  //           targetDate
                  //         ).getDate()}`
                  //     : new Date(targetDate).getDate().toString().length < 2
                  //     ? `${new Date(targetDate).getFullYear()}-${new Date(targetDate).getMonth() + 1}-0${new Date(
                  //         targetDate
                  //       ).getDate()}`
                  //     : `${new Date(targetDate).getFullYear()}-${new Date(targetDate).getMonth() + 1}-${new Date(
                  //         targetDate
                  //       ).getDate()}`
                  // }
                />
              </div>
            </Grid>
            <Grid item xs={4}>
              <div
                style={{
                  color: '#2f4858',
                  display: 'flex',
                }}
              >
                <Iconify icon="eva:shopping-cart-outline" sx={{ width: 16, height: 16, mr: 1.5 }} />

                <Input
                  type="text"
                  value={reference}
                  onChange={(e) => {
                    setReference(e.target.value);
                  }}
                  placeholder="Réference"
                  required
                />
              </div>
            </Grid>
            <Grid item xs={4}>
              <div
                style={{
                  color: '#2f4858',
                  display: 'flex',
                }}
              >
                <Iconify icon="eva:person-outline" sx={{ width: 16, height: 16, mr: 1.5 }} />

                <Input
                  type="text"
                  value={ordererName}
                  onChange={(e) => {
                    setOrdererName(e.target.value);
                  }}
                  placeholder="Nom du Client"
                  required
                />
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ marginTop: 1 }}>
            <Grid item xs={4}>
              <div
                style={{
                  color: '#2f4858',
                  display: 'flex',
                }}
              >
                <Iconify icon="eva:phone-outline" sx={{ width: 16, height: 16, mr: 1.5 }} />

                <Input
                  type="text"
                  value={ordererPhone}
                  onChange={(e) => {
                    setOrdererPhone(e.target.value);
                  }}
                  placeholder="Téléphone du Client"
                />
              </div>
            </Grid>
            <Grid item xs={4}>
              <div
                style={{
                  color: '#2f4858',
                  display: 'flex',
                }}
              >
                <Iconify icon="eva:home-outline" sx={{ width: 16, height: 16, mr: 1.5 }} />

                <Input
                  type="text"
                  value={address1}
                  onChange={(e) => {
                    setAddress1(e.target.value);
                  }}
                  placeholder="Adresse 1"
                />
              </div>
            </Grid>
            <Grid item xs={4}>
              <div
                style={{
                  color: '#2f4858',
                  display: 'flex',
                }}
              >
                <Iconify icon="eva:home-outline" sx={{ width: 16, height: 16, mr: 1.5 }} />

                <Input
                  type="text"
                  value={address2}
                  onChange={(e) => {
                    setAddress2(e.target.value);
                  }}
                  placeholder="Adresse 2"
                />
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ marginTop: 1 }}>
            <Grid item xs={4}>
              {/* <Typography gutterBottom variant="caption" sx={{ display: 'block' }}>
                  <Iconify icon="eva:car-outline" sx={{ width: 16, height: 16, mr: 1.5 }} />

                  <Autocomplete
                    id="combo-box-demo"
                    options={camionList}
                    value={vehicule?.registrationNb}
                    renderInput={(params) => (
                      <TextField {...params} variant="standard" size="small" sx={{ width: '200px' }} />
                    )}
                    onChange={(event, newValue) => {
                      setVehicule(newValue);
                    }}
                  />
                </Typography> */}
              <div
                style={{
                  color: '#2f4858',
                  display: 'flex',
                }}
              >
                <Iconify icon="eva:car-outline" sx={{ width: 16, height: 16, mr: 1.5 }} />

                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={camionList}
                  value={vehicule?.registrationNb ? vehicule?.registrationNb : null}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      size="small"
                      sx={{ width: '200px' }}
                      placeholder="Véhicule"
                    />
                  )}
                  onChange={(event, newValue) => {
                    setVehicule(newValue);
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={4}>
              <div
                style={{
                  color: '#2f4858',
                  display: 'flex',
                }}
              >
                <Iconify icon="fluent:person-support-24-regular" sx={{ width: 16, height: 16, mr: 1.5 }} />

                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={commercialList}
                  value={owner?.userName ? owner?.userName : null}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      size="small"
                      sx={{ width: '200px' }}
                      placeholder="Commercial"
                    />
                  )}
                  onChange={(event, newValue) => {
                    handleChangeOwner(event, newValue);
                  }}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    if (params.inputValue !== '') {
                      filtered.push({
                        inputValue: params.inputValue,
                        userName: params.inputValue,
                      });
                    }

                    return filtered;
                  }}
                  getOptionLabel={(option) => {
                    // e.g value selected with enter, right from the input
                    if (typeof option === 'string') {
                      return option;
                    }
                    if (option.inputValue) {
                      return option.inputValue;
                    }
                    return option.userName;
                  }}
                />
              </div>
            </Grid>
          </Grid>
        </div>
      </>
      <CardContent>
        <AddArticles
          status={status}
          items={listArticle}
          listArticle={listArticle}
          setListArticle={setListArticle}
          idOrder
        />
      </CardContent>
      <Button
        variant="contained"
        sx={{ marginTop: '1%', marginBottom: '1%' }}
        onClick={() => {
          AddNewCommand();
        }}
        disabled={disableAddButton}
      >
        Ajouter
      </Button>
      <Button variant="outlined" sx={{ marginTop: '1%', marginBottom: '1%', marginLeft: '1%' }} onClick={handleClose}>
        Annuler
      </Button>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={alert.open}
        autoHideDuration={3000}
        onClose={CloseAlert}
      >
        <Alert onClose={CloseAlert} severity={alert.type} sx={{ width: '100%' }}>
          {alert.message}
        </Alert>
      </Snackbar>
      {/* </Card> */}
    </Grid>
  );
}
