/* eslint-disable */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

// material
import {
  Grid,
  Input,
  Button,
  Autocomplete,
  TextField,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

// utils
import Iconify from '../../../components/Iconify';
import { jsonWebService } from 'src/infrastructure/web-service';
import * as eventsService from 'src/infrastructure/sub-pub-events/eventsSystem';
import { NOTIFICATION_TOAST_EVENT } from 'src/infrastructure/sub-pub-events/eventTypes';
import { URL_WS } from 'src/constants';
import Translation from 'src/Translation';// import { fShortenNumber } from '../../../utils/formatNumber';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// //
// import SvgIconStyle from '../../../components/SvgIconStyle';
// import Iconify from '../../../components/Iconify';

// ----------------------------------------------------------------------


const useStyles = makeStyles({

  button: {
    backgroundColor: '#2F95CA',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#2F95CA',
      color: '#fff',
      borderColor: '#2F95CA'
    },
  },
  buttonOutlined: {
    backgroundColor: '#fff',
    color: '#2F95CA',
    borderColor: '#2F95CA',
    '&:hover': {
      backgroundColor: '#fff',
      color: '#2F95CA',
      borderColor: '#2F95CA'
    },
  }
})
// ----------------------------------------------------------------------

BlogAddObjectifCard.propTypes = {
  item: PropTypes.object.isRequired,
  index: PropTypes.number,
};


export default function BlogAddObjectifCard({ setShowAddObjectifForm, handleClose, getObjectifs }) {
  const classes = useStyles()
  const [dateDebut, setDateDebut] = useState(null);
  const [dateFin, setDateFin] = useState(null);
  const [classification, setClassification] = useState('');
  const [assignment, setAssignment] = useState('');
  const [objectif, setObjectif] = useState('');
  const [disableAddButton, setDisableAddButton] = useState(false);
  const [listSpace, setListSpace] = useState([]);
  const listCategorie = [
    {
      label: 'CONS-LOW', value: 'CONS-LOW'
    },
    {
      label: 'CONS-MID', value: 'CONS-MID'
    },
    {
      label: 'CONS-HIGH', value: 'CONS-HIGH'
    },
    {
      label: 'COM-LOW', value: 'COM-LOW'
    },
    {
      label: 'COM-MID', value: 'COM-MID'
    },
    {
      label: 'COM-HIGH', value: 'COM-HIGH'
    }

  ]


  useEffect(() => {
    let list = []
    jsonWebService
      .get(`${URL_WS}/espace/getAll`)
      .then((response) => {
        response.data?.map(r => {
          let esp = { label: r.name, value: r._id }
          list.push(esp)
        })
        setListSpace(list)
      })
      .catch((err) => { });
  }, []);

  const AddNewObjectif = () => {
    setDisableAddButton(true)
    let data = {
      starDate: dateDebut.format('YYYY-MM-DD') ,
      endDate: dateFin,
      assignment: assignment.value,
      classification: classification.value,
      objectif: objectif,
    };

    jsonWebService
      .post(`${URL_WS}/objectif/add`, data)
      .then((response) => {
        setDateDebut(null);
        setDateFin(null);
        setAssignment('');
        setClassification('');
        setObjectif('');
        setShowAddObjectifForm(false);
        setDisableAddButton(false)
        eventsService.publish(NOTIFICATION_TOAST_EVENT, { toastMessage: <Translation message={response.message} />, variant: response.status });
        getObjectifs();
      })
      .catch((err) => { });
  };

  return (
    <Grid item md="12">
      {/* <Card sx={{ position: 'relative' }}> */}
      <>
        <div className="ml-5">
          <Grid container spacing={2} sx={{ marginTop: 1 }}>
            <Grid item xs={4}>
              <div
                style={{
                  color: '#2f4858',
                  display: 'flex',
                }}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    inputFormat="DD/MM/YYYY"
                    label="Date début"
                    value={dateDebut}
                    onChange={(newValue) => {
                      setDateDebut(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} helperText={null} />}
                  />
                </LocalizationProvider>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div
                style={{
                  color: '#2f4858',
                  display: 'flex',
                }}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    inputFormat="DD/MM/YYYY"
                    label="Date fin"
                    value={dateFin}
                    onChange={(newValue) => {
                      setDateFin(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} helperText={null} />}
                  />
                </LocalizationProvider>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div
                style={{
                  color: '#2f4858',
                  display: 'flex',
                }}
              >
                <Iconify icon="material-symbols:store-rounded" sx={{ width: 16, height: 16, mr: 1.5 }} />

                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={listSpace}
                  value={assignment}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      size="small"
                      sx={{ width: '200px' }}
                      placeholder="Point de vente"
                    />
                  )}
                  onChange={(event, newValue) => {
                    setAssignment(newValue)
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={4}>
              <div
                style={{
                  color: '#2f4858',
                  display: 'flex',
                }}
              >
                <Iconify icon="material-symbols:category" sx={{ width: 16, height: 16, mr: 1.5 }} />

                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={listCategorie}
                  value={classification}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      size="small"
                      sx={{ width: '200px' }}
                      placeholder="Catégorie"
                    />
                  )}
                  onChange={(event, newValue) => {
                    setClassification(newValue)
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={4}>
              <div
                style={{
                  color: '#2f4858',
                  display: 'flex',
                }}
              >
                <Iconify icon="octicon:goal-16" sx={{ width: 16, height: 16, mr: 1.5 }} />

                <Input
                  type="number"
                  value={objectif}
                  onChange={(e) => {
                    setObjectif(e.target.value);
                  }}
                  placeholder="Objectif"
                />
              </div>
            </Grid>
          </Grid>
        </div>
      </>
      <Button
        variant="contained"
        sx={{ marginTop: '1%', marginBottom: '1%' }}
        onClick={() => {
          AddNewObjectif();
        }}
        disabled={disableAddButton}
        className={classes.button}
      >
        Ajouter
      </Button>
      <Button className={classes.buttonOutlined} variant="outlined" sx={{ marginTop: '1%', marginBottom: '1%', marginLeft: '1%' }} onClick={handleClose}>
        Annuler
      </Button>
      {/* </Card> */}
    </Grid>
  );
}
