/* eslint-disable */
import React, { useRef, useState, useEffect, useCallback } from 'react';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import mapboxgl from 'mapbox-gl';
import mbxGeocoding from '@mapbox/mapbox-sdk/services/geocoding';

// eslint-disable-next-line import/no-webpack-loader-syntax
import MapboxWorker from 'worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker'; // Load worker code separately with worker-loader

mapboxgl.workerClass = MapboxWorker; // Wire up loaded worker to be used instead of the default

mapboxgl.accessToken =
  'pk.eyJ1IjoicmF3ZW5tZXJzYW5pIiwiYSI6ImNsNmF6ejFsYTBtZXYzanFmcXp6MHJoZnUifQ.w144brpzhoKqRv9MODG5rQ';

export default function Map(props) {
  const { country, usersPositions} = props;
  const mapContainer = useRef(null);
  const map = useRef(null);
  const marker = useRef(null);
  const geocoder = useRef(null);
  const  [currentMarkers, setCurrentMarkers] = useState([]);

  const [zoom] = useState(5);

  const fetchData = useCallback(() => {
    const geocodingClient = mbxGeocoding({
      accessToken: mapboxgl.accessToken
    });

    return geocodingClient
      .forwardGeocode({
        // query: city ? city.name : country?.name,
        // query: country.name,
        query: country,
        // countries: [country?.country_code],
        limit: 1
      })
      .send()
      .then(response => {
        const match = response.body;
        // console.log('match', match);
        const coordinates = match.features[0]?.geometry.coordinates;
        const placeName = match.features[0]?.place_name;
        const center = match.features[0]?.center;

        let name = country;

        geocoder.current.setPlaceholder('Search for places in ' + name);
        geocoder.current.setBbox(match.features[0].bbox);

        geocoder.current.setCountries(match.features[0].properties.short_code);

        return {
          type: 'Feature',
          center: center,
          geometry: {
            type: 'Point',
            coordinates: coordinates
          },
          properties: {
            description: placeName
          }
        };
      });
  }, [country]);

  const setPins = useCallback(name => {
    const geocodingClient = mbxGeocoding({
      accessToken: mapboxgl.accessToken
    });

    return geocodingClient
      .forwardGeocode({
        // query: city ? city.name : country?.name,
        // query: country.name,
        query: name,
        // countries: [country?.country_code],
        limit: 1
      })
      .send()
      .then(response => {
        const match = response.body;
        const coordinates = match.features[0]?.geometry.coordinates;
        const placeName = match.features[0]?.place_name;
        const center = match.features[0]?.center;

        return {
          type: 'Feature',
          center: center,
          geometry: {
            type: 'Point',
            coordinates: coordinates
          },
          properties: {
            description: placeName
          }
        };
      });
  }, []);

  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [0, 0],
      zoom: zoom
    });

    // const geocoder = new MapboxGeocoder({
    //   accessToken: mapboxgl.accessToken,
    //   mapboxgl: mapboxgl,
    //   placeholder: "Search for places",
    //   marker: false,
    //   //   countries: ["al"],
    //   bbox: bbox,
    //   //   proximity: {
    //   //     longitude: -122.25948,
    //   //     latitude: 37.87221,
    //   //   },
    // });

    // console.log(geocoder);

    // map.current.addControl(geocoder);

    geocoder.current = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      mapboxgl: mapboxgl,
      //   placeholder: "Search for places",
      marker: false,
      width: '200px'
      //   countries: ["af"],
      //   bbox: bbox,
      //   proximity: {
      //     longitude: -122.25948,
      //     latitude: 37.87221,
      //   },
    });

    map.current.addControl(geocoder.current);
    map.current.addControl(new mapboxgl.FullscreenControl());

    // marker.current = new mapboxgl.Marker()

    // const marker = new mapboxgl.Marker({
    //   draggable: true
    // })
    //   .setLngLat([lng, lat])
    //   .addTo(map.current);

    // marker.on("dragend", onDragEnd);

    // function onDragEnd() {
    //   const lngLat = marker.getLngLat();
    //   console.log(`Longitude: ${lngLat.lng}<br />Latitude: ${lngLat.lat}`);
    //   // coordinates.style.display = "block";
    //   // coordinates.innerHTML = `Longitude: ${lngLat.lng}<br />Latitude: ${lngLat.lat}`;
    // }

    // fetchData();
  });

  useEffect(() => {
    const results = fetchData();
    results.then(async resp => {
      map.current.flyTo({
        center: resp.center
      });
    });
    let markersList = currentMarkers
    if(markersList.length >0){
      markersList.map(m => {
        m.remove()
      })
    }
    
    if(usersPositions.length > 0){
      usersPositions.map(position => {
        // setPins(city.name).then(async resp => {
          // map.current.flyTo({
          //   center: resp.center
          // });
          position.positions.map(p => {
            const el = document.createElement('div');
            // const width = marker.properties.iconSize[0];
            // const height = marker.properties.iconSize[1];
            el.className = 'marker_city';
            el.style.backgroundImage = `url(https://cdn-icons-png.flaticon.com/512/684/684908.png)`;
            el.style.width = `40px`;
            el.style.height = `40px`;
            el.style.backgroundSize = '100%';
  
            // el.addEventListener('click', () => {
            //   window.alert(marker.properties.message);
            // });
  
            // Add markers to the map.
            // new mapboxgl.Marker(el).setLngLat(resp.center).addTo(map.current);
  
            const popup = new mapboxgl.Popup({ offset: 25 }).setText(`${position.userDetails.firstName } ${position.userDetails.lastName} ${p.time}`);
            // new mapboxgl.Marker(el).setLngLat([p.longitude, p.latitude]).setPopup(popup).addTo(map.current);
            var oneMarker= new mapboxgl.Marker(el).setLngLat([p.longitude, p.latitude]).setPopup(popup).addTo(map.current);

            // save tmp marker into currentMarkers
            markersList.push(oneMarker);
            setCurrentMarkers(markersList)
          })

         

          // new mapboxgl.Popup().setLngLat(resp.center).setHTML(airport.name).addTo(map.current);
        // });
    })
    }
   
    // hotels.map(city => {
    //   setPins(city.name).then(async resp => {
    //     // map.current.flyTo({
    //     //   center: resp.center
    //     // });

    //     const el = document.createElement('div');
    //     // const width = marker.properties.iconSize[0];
    //     // const height = marker.properties.iconSize[1];
    //     el.className = 'marker_city';
    //     el.style.backgroundImage = `url(https://cdn-icons-png.flaticon.com/512/5193/5193714.png)`;
    //     el.style.width = `20px`;
    //     el.style.height = `20px`;
    //     el.style.backgroundSize = '100%';

    //     // el.addEventListener('click', () => {
    //     //   window.alert(marker.properties.message);
    //     // });

    //     // Add markers to the map.

    //     const popup = new mapboxgl.Popup({ offset: 25 }).setText(city.name);
    //     new mapboxgl.Marker(el).setLngLat(resp.center).setPopup(popup).addTo(map.current);

    //     // new mapboxgl.Popup().setLngLat(resp.center).setHTML(airport.name).addTo(map.current);
    //   });
    // });
    // airports.map(city => {
    //   setPins(city.name).then(async resp => {
    //     // map.current.flyTo({
    //     //   center: resp.center
    //     // });

    //     const el = document.createElement('div');
    //     // const width = marker.properties.iconSize[0];
    //     // const height = marker.properties.iconSize[1];
    //     el.className = 'marker_city';
    //     el.style.backgroundImage = `url(https://cdn-icons-png.flaticon.com/512/3448/3448279.png)`;
    //     el.style.width = `40px`;
    //     el.style.height = `40px`;
    //     el.style.backgroundSize = '100%';

    //     // el.addEventListener('click', () => {
    //     //   window.alert(marker.properties.message);
    //     // });

    //     // Add markers to the map.

    //     const popup = new mapboxgl.Popup({ offset: 25 }).setText(city.name);
    //     new mapboxgl.Marker(el).setLngLat(resp.center).setPopup(popup).addTo(map.current);

    //     // new mapboxgl.Popup().setLngLat(resp.center).setHTML(airport.name).addTo(map.current);
    //   });
    // });
  }, [fetchData, usersPositions]);

  const emptyMap = () => {
    const el = document.createElement('div');
    // const width = marker.properties.iconSize[0];
    // const height = marker.properties.iconSize[1];
    el.className = 'marker_city';
    el.style.backgroundImage = `url(https://cdn-icons-png.flaticon.com/512/684/684908.png)`;
    el.style.width = `40px`;
    el.style.height = `40px`;
    el.style.backgroundSize = '100%';
  }

  return (
    <div ref={mapContainer} className="map-container" style={{ height: '500px', width: '100%' }} />
  );
}
