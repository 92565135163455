/* eslint-disable */
import { filter } from 'lodash';
import React, { useEffect, useState } from 'react';
// material
import {
  Stack,
  Button,
  Container,
  Switch,
  Typography,
} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
// components
import Page from '../components/Page';
import Iconify from '../components/Iconify';
import { Scheduler, useScheduler } from "@aldabil/react-scheduler";
import { UserListToolbar } from 'src/sections/@dashboard/user';
import { jsonWebService } from 'src/infrastructure/web-service';
import { URL_WS } from 'src/constants';
import moment from 'moment';
import { fr } from 'date-fns/locale';
import ReactHtmlTableToExcel from 'react-html-table-to-excel';
import './calendar.css'

// ----------------------------------------------------------------------
const useStyles = theme =>({
  
  buttonExport: {
    backgroundColor: '#2F95CA',
    color: '#fff',
    border: '0',
    minWidth:'64px',
    padding:'6px 16px',
    borderRadius:'8px',
    fontFamily:'Public Sans,sans-serif',
    fontSize:'0.875rem',
    fontWeight:'700',
    cursor:'pointer',
    boxShadow:'0 8px 16px 0 rgb(47 149 202 / 24%)',
    lineHeight:'1.7142857142857142',
    '&:hover': {
      backgroundColor: '#2F95CA',
      color: '#fff',
      borderColor:'#2F95CA'
  },
  
},})

class Calendar extends React.Component {
  state= {
    viewMode : 'tabs',
    usersList: [],
    eventsList: [],
    dataToExport: []
  }

  changeTabsView(){
    const {viewMode} = this.state
    if(viewMode === 'defaultt'){
      this.setState({ viewMode : 'tabs' })
    }
    else {
      this.setState({ viewMode : 'defaultt' })
    }
  }

  componentDidMount(){
    let listUser = []
    let listEvents = []
    jsonWebService
      .get(`${URL_WS}/calendar/getAll`)
      .then((response) => {
        response.data.map(r => {
          let user = {
          admin_id: r.user._id,
          title: `${r.user.firstName} ${r.user.lastName}`,
          mobile: "507487620",
          color: "#0C6690"
        }
        let evnt = {
          event_id: r._id,
          title: "Check In - Check Out",
          start: r.ChekinInTime ? new Date(moment(r.ChekinInTime).format("YYYY/MM/DD HH:mm")) : new Date(moment(r.WorkDate).startOf('day').format("YYYY/MM/DD HH:mm")),
          end: r.ChekinOutTime ? new Date(moment(r.ChekinOutTime).format("YYYY/MM/DD HH:mm")) : new Date(moment(r.WorkDate).endOf('day').format("YYYY/MM/DD HH:mm")),
          color: r.status === 'present' ? '#17BD6B' : r.status === 'late' ? '#F0A208' : r.status === 'absent' ? '#C70039' : '#99abb3',
          admin_id: r.user._id,
          adminName: `${r.user.firstName} ${r.user.lastName}`,
          isCheckIn: r.ChekinInTime ? true : false,
          isCheckOut: r.ChekinOutTime ? true : false
        }
        if(listUser.filter(u => u.admin_id === r.user._id).length == 0){
          listUser.push(user)
        }
        listEvents.push(evnt)
        })
        console.log('listUser', listUser)
        console.log('listEvents', listEvents)
        console.log('response.data', response.data)
        this.setState({
          usersList: listUser,
          eventsList: listEvents,
          dataToExport : response.data
        })
        
      })
      .catch((err) => { });
  }

  render() {
    const { viewMode, usersList, eventsList, dataToExport } = this.state
    const { classes } = this.props;

    const tableToExport = <div style={{display:'none'}}>
      <table id="table-to-xls">
        <tr>
          <th>Nom & Prenom</th>
          <th>Point de vente</th>
          <th>Date</th>
          <th>CheckIn</th>
          <th>CheckOut</th>
          <th>Statut</th>
        </tr>
        {dataToExport.length > 0 ? dataToExport.map(row => (
          <tr>
            <td>{row.user.firstName} {row.user.lastName}</td>
            <td>{row.espace.name}</td>
            <td>{moment(row.WorkDate).format("YYYY/MM/DD")}</td>
            <td>{row.ChekinInTime ? moment(row.ChekinInTime).format("HH:mm") : ""}</td>
            <td>{row.ChekinOutTime ? moment(row.ChekinOutTime).format("HH:mm") : ""}</td>
            <td>{row.status}</td>
          </tr>
        )) :
        <div></div>}
      </table>
    </div>


    if(usersList.length === 0){
      return(
        <>
        Waiting ...
        </>
      )
    }
    else {
      if(viewMode === 'tabs'){
        return (
          <Page title="Calendar" >
            <Container>
              <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                <UserListToolbar />
                <ReactHtmlTableToExcel
                    id="test-table-xls-button"
                    className={classes.buttonExport}
                    table="table-to-xls"
                    filename="tablexls"
                    sheet="tablexls"
                    buttonText="Exporter"/>
                <div style={{display:'flex'}}>
                <Switch
                size="small"
                inputProps={{ 'aria-label': 'ant design' }}
                onChange={e => this.changeTabsView()}
                style={{ marginTop: '0%' }}
              />
              <Typography>Show all</Typography>
              </div>
              </Stack>
              
              <Scheduler
                sx={{ overflowX: 'inherit' }}
                locale={fr}
                editable={false}
                deletable={false}
                view="month"
                hourFormat="24"
                events={eventsList}
                resources={usersList}
                resourceFields={{
                  idField: "admin_id",
                  textField: "title",
                  subTextField: "mobile",
                  avatarField: "title",
                  colorField: "color"
                }}
                month={{
                  startHour: 0, 
                  endHour: 24,
                }}
                week={{
                  startHour: 0, 
                  endHour: 24,
                }}
                day={{
                  startHour: 0, 
                  endHour: 24,
                }}
                // fields={
                //   [
                //     {
                //       name: "description",
                //       type: "input",
                //       config: { label: "Description", required: true, min: 3, email: true, variant: "outlined" }
                //     }
                //   ]
                // }
                resourceViewMode= 'tabs'
                viewerExtraComponent={(fields, event) => {
                  console.log('event', event)
                  return (
                    <>
                    <div>
                      <Typography
                      style={{ display: "flex", alignItems: "center", fontSize:'medium' }}
                      color="textSecondary"
                      variant="caption"
                      noWrap
                    >
                      <Iconify icon="mdi:user" /> {event.adminName}
                    </Typography>
                    </div>
                    <div>
                    <Typography
                      style={{ display: "flex", alignItems: "center", fontSize:'medium' }}
                      color="textSecondary"
                      variant="caption"
                      noWrap
                    >
                      <Iconify icon="ic:round-log-in" /> {event.isCheckIn ? moment(event.start).format("YYYY/MM/DD HH:mm")  : '--'}
                    </Typography>
                    </div>
                    <div>
                    <Typography
                      style={{ display: "flex", alignItems: "center", fontSize:'medium' }}
                      color="textSecondary"
                      variant="caption"
                      noWrap
                    >
                      <Iconify icon="ic:round-log-out" /> {event.isCheckOut ? moment(event.end).format("YYYY/MM/DD HH:mm") : '--'}
                    </Typography>
                    </div>
                    </>
                  );
                }}
              />
            </Container>
            {tableToExport}
          </Page>
        );
      }
      else {
        return (
          <Page title="Calendar" >
            <Container>
              <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                <UserListToolbar />
                <Button variant="contained" style={{marginRight: '11%'}} startIcon={<Iconify icon="eva:plus-fill" />}>
                  Exporter
                </Button>
                <div style={{display:'flex'}}>
                <Switch
                size="small"
                inputProps={{ 'aria-label': 'ant design' }}
                onChange={e => this.changeTabsView()}
                style={{ marginTop: '0%' }}
              />
              <Typography>Show all</Typography>
              </div>
              </Stack>
              <div>
              <Scheduler
                sx={{ overflowX: 'inherit' }}
                locale= {fr}
                editable={false}
                deletable={false}
                view="month"
                hourFormat="24"
                events={eventsList}
                resources={usersList}
                resourceFields={{
                  idField: "admin_id",
                  textField: "title",
                  subTextField: "mobile",
                  avatarField: "title",
                  colorField: "color"
                }}
                month={{
                  startHour: 0, 
                  endHour: 24,
                }}
                week={{
                  startHour: 0, 
                  endHour: 24,
                }}
                day={{
                  startHour: 0, 
                  endHour: 24,
                }}
                fields={
                  [
                    {
                      name: "description",
                      type: "input",
                      config: { label: "Description", required: true, min: 3, email: true, variant: "outlined" }
                    }
                  ]
                }
                resourceViewMode= 'default | tabs'
              />
              </div>
            </Container>
            {tableToExport}
          </Page>
        );
      }
    }
    
    
  }

}

export default withStyles(useStyles) (Calendar)
