/* eslint-disable */
// component
import { lte } from 'lodash';
import { getCurrentUser } from 'src/services/user/current-user';
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;
let navConfig;

navConfig = [
  {
    title: 'Ressources',
    path: '/dashboard/user',
    icon: getIcon('material-symbols:account-tree'),
    children: [
      {
        title: 'Utilisateurs',
        path: '/dashboard/user',
        icon: getIcon('eva:people-fill'),
      },
      {
        title: 'Espaces',
        path: '/dashboard/space',
        icon: getIcon('mdi:warehouse'),
      },
      {
        title: 'Articles',
        path: '/dashboard/articles',
        icon: getIcon('ooui:articles-rtl'),
      },
    ],
  },
  {
    title: 'Calendrier',
    path: '/dashboard/calendar',
    icon: getIcon('material-symbols:calendar-month-sharp'),
  },
  {
    title: 'Positions',
    path: '/dashboard/locations',
    icon: getIcon('mdi:google-maps'),
  },
  {
    title: 'Objectifs',
    path: '/dashboard/commandes',
    icon: getIcon('octicon:goal-16'),
  },
  // {
  //   title: 'Articles',
  //   path: '/dashboard/articles',
  //   icon: getIcon('carbon:delivery-parcel'),
  // },
  // {
  //   title: 'Clients',
  //   path: '/dashboard/client',
  //   icon: getIcon('bxs:user-badge'),
  // },
  // {
  //   title: 'Statistiques',
  //   path: '/404',
  //   icon: getIcon('eva:alert-triangle-fill'),
  // },
];

export default navConfig;
