/* eslint-disable */
import { useEffect } from 'react';
import * as React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ReactToPrint from 'react-to-print';

import {
  createTheme,
  ThemeProvider,
  Typography,
  Link,
  styled,
  Rating,
  Checkbox,
  ImageList,
  ImageListItem,
  ToggleButton,
  ToggleButtonGroup,
  Box,
  Button,
  Grid,
} from '@mui/material';
import { fDate, fDateTime, fDateTimeSuffix, fSimpleDate } from '../../../utils/formatTime';
import Iconify from '../../../components/Iconify';
import './style.css';
import { jsonWebService } from '../../../infrastructure/web-service';
import { URL_WS } from '../../../constants';
import RatingQuestion from 'src/components/questionComponents/RatingQuestion';
import RadioQuestion from 'src/components/questionComponents/RadioQuestion';
import ToggleQuestion from 'src/components/questionComponents/ToggleQuestion';
import TextQuestion from 'src/components/questionComponents/TextQuestion';
import { editedData, onChangeRadio, onChangeRating, onChangeText, onChangeToggle, resetData, sendEditedData } from './ManageSatisfactionFormEdit';
import { generatePath } from 'react-router-dom';
import LogoLenovo from 'src/components/LogoLenovo';

// import Slide from '@mui/material/Slide';

const theme = createTheme({
  overrides: {
    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(0,0,0,0.2)',
      },
    },
  },
});

const TitleStyle = styled(Link)({
  height: 44,
  overflow: 'hidden',
  WebkitLineClamp: 2,
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  fontWeight: 'bold',
});
export default function AlertDialogSlide(props) {
  const { open, setOpen, order, formSatisfaction, setFeedbackFormChanged,feedbackFormChanged, handleClickShowForm } = props;

  const componentRef = React.useRef();

  const [questions, setQuestions] = React.useState([]);
  const [tablesNumber, setTablesNumber] = React.useState([]);
  const [disableEditButton, setDisableEditButton] = React.useState(false)

  const [resetValues, setResetValues] = React.useState(false);

  // const Transition = React.forwardRef(function Transition(props, ref) {
  //   return <Slide direction="up" ref={ref} {...props} />;
  // });


  useEffect(() => {
    let orderedQuestions = [];
    if (formSatisfaction) {
      formSatisfaction.form.map((q, index) => {
        let question = formSatisfaction.form.filter(o => o.statement.id.number === index + 1)
        orderedQuestions.push(question)
      })
    }
    setQuestions(orderedQuestions)
    setTablesNumber(((orderedQuestions.length / 3) + (orderedQuestions.length % 3)))
  }, [formSatisfaction, props.open]);

  const openFullscreen = (imageId) => {
    // document.getElementById(imageId)?.requestFullscreen();
    // document.getElementById(imageId).className = ' show_image';
    document.getElementById(imageId).style.transform = 'scale(5.5)';
    document.getElementById(imageId).style.transition = 'transform 0.25s ease';
    // document.getElementById(imageId)?.style.transform = "scale(1.5) transform:translate(0,-50%)";
  };

  const hideButton = () => {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < document.getElementsByName('Print').length; i++) {
      document.getElementsByName('Print')[i].style.display = 'none';
      document.getElementsByName('actionButtons')[i].style.display = 'none';
      // document.getElementsByName('emptyDiv')[i].style.display = 'flex';
      // document.getElementsByName('emptyDiv')[i].style.marginLeft = '25%';
      document.getElementsByName('content')[i].style.overflow = 'hidden';
    }
  };

  const showButton = () => {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < document.getElementsByName('Print').length; i++) {
      document.getElementsByName('Print')[i].style.display = 'flex';
      document.getElementsByName('actionButtons')[i].style.display = '';
      // document.getElementsByName('emptyDiv')[i].style.display = 'none';
      // document.getElementsByName('emptyDiv')[i].style.marginLeft = '0';
      document.getElementsByName('content')[i].style.overflow = '';
    }
  };

  const handleClose = () => {
    setResetValues(!resetValues);
    resetData();
    setDisableEditButton(false)
    setOpen(false);
    if(setFeedbackFormChanged){
      setFeedbackFormChanged(!feedbackFormChanged);
    }
    else {
      handleClickShowForm(undefined);
    }
  };

  const handleCancel = () => {
    setResetValues(!resetValues);
    resetData();
    setDisableEditButton(false)
    setOpen(false);
  }

  const editData = () => {
    setDisableEditButton(true)
    sendEditedData(formSatisfaction?._id, handleClose)
  }

  const generatePath = (order, item) => {
    let date = new Date(order.targetDate).getDate()
    let month = new Date(order.targetDate).getMonth() + 1
    if(date.toString().length < 2 ) {
      if(month.toString().length < 2) {
        return `http://51.210.242.227:5001/uploads/${order.reference}_0${date}_0${month}_${new Date(order.targetDate).getFullYear()}/${item.name}`
      }
      else {
        return `http://51.210.242.227:5001/uploads/${order.reference}_0${date}_${month}_${new Date(order.targetDate).getFullYear()}/${item.name}`
      }
    }

    else if(month.toString().length < 2) {
      return `http://51.210.242.227:5001/uploads/${order.reference}_${date}_0${month}_${new Date(order.targetDate).getFullYear()}/${item.name}`
    }

    return `http://51.210.242.227:5001/uploads/${order.reference}_${date}_${month}_${new Date(order.targetDate).getFullYear()}/${item.name}`

  }

  return (
    <div>
      <ThemeProvider theme={theme}>
        <Dialog
          theme={theme}
          open={open}
          // TransitionComponent={Transition}
          keepMounted
          onClose={handleCancel}
          aria-describedby="alert-dialog-slide-description"
          fullWidth
          maxWidth="md"
          
        >
          <div ref={componentRef}
          style={{ backgroundColor: '#fff' }}>

          {/* <DialogTitle>{'Référence commande'}</DialogTitle> */}
          <Box sx={{ px: 2, py: 3, display: 'inline-flex', alignItems: 'center', justifyContent: 'space-between', width:'100%' }}>
            <LogoLenovo location="bonLivraison" style={{ marginLeft: '-50px' }} />
            <Typography variant="h6" style={{ textAlign: 'center' }}>
              <div style={{ display: 'flex' }}><p className='firstPartFormTitle'>Formulaire de</p>&nbsp; <p className='secondPartFormTitle'>satisfaction</p></div>
            </Typography>
            {/* <div name="emptyDiv" style={{ display: 'none' }} /> */}
            <div className="flex justify-content">
              <div>
                <Typography gutterBottom variant="caption" sx={{ display: 'block' }}>
                  <Iconify icon="eva:shopping-cart-outline" sx={{ width: 16, height: 16, mr: 1.5 }} />
                  {order.reference}
                </Typography>
                <Typography gutterBottom variant="caption" sx={{ color: '#2f4858', display: 'block' }}>
                  <Iconify icon="eva:person-outline" sx={{ width: 16, height: 16, mr: 1.5 }} />
                  {order.orderer?.name}
                </Typography>
                <Typography gutterBottom variant="caption" sx={{ display: 'block' }}>
                  <Iconify icon="eva:calendar-outline" sx={{ width: 16, height: 16, mr: 1.5 }} />
                  {fSimpleDate(order.targetDate)}
                </Typography>
                <Typography gutterBottom variant="caption" sx={{ display: 'block' }}>
                  <Iconify icon="fluent:person-support-24-regular" sx={{ width: 16, height: 16, mr: 1.5 }} />
                  {order.owner?.userName}
                </Typography>

              </div>
            </div>
            <ReactToPrint
              trigger={() => {
                // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                // to the root node of the returned component as it will be overwritten.
                return (
                  <Button
                    Name="Print"
                    variant="contained"
                    style={{ height: 40, width: 40, marginTop: '-13%' }}
                  // startIcon={<Iconify icon="fluent:print-20-regular" />}
                  >
                    <Iconify icon="fluent:print-20-regular" />
                  </Button>
                );
              }}
              content={() => componentRef.current}
              onBeforeGetContent={(e) => hideButton()}
              onAfterPrint={(e) => showButton()}
            />
          </Box>
          <DialogTitle>
            <hr align="LEFT" noshade="" color='#9f9fa0' style={{ marginTop: '-5%', marginBottom:'-4%' }} />
          </DialogTitle>
          <DialogContent name="content">
          <Box sx={{ px: 2, display: 'flex', justifyContent: 'space-between' }}>
              <Typography gutterBottom variant="caption" sx={{ display: 'block' }}>
                Début intervention
              </Typography>
              <Typography gutterBottom variant="caption" sx={{ display: 'block' }}>
                Fin intervention
              </Typography>
            </Box>
            <Box sx={{ px: 2, display: 'flex', justifyContent: 'space-between' }}>
              <Typography gutterBottom variant="caption" sx={{ display: 'block' }}>
                <Iconify icon="eva:calendar-outline" sx={{ width: 16, height: 16, mr: 1.5 }} />
                {formSatisfaction ? fDateTime(formSatisfaction?.StartDate) : ''}
              </Typography>
              <Typography gutterBottom variant="caption" sx={{ display: 'block' }}>
                <Iconify icon="eva:calendar-outline" sx={{ width: 16, height: 16, mr: 1.5 }} />
                {formSatisfaction ? fDateTime(formSatisfaction?.EndDate) : ''}
              </Typography>
            </Box>
            {questions.map((x, index) => (
              index < tablesNumber && <SliceQuestions tablesNumber={tablesNumber} questions={questions} index={index} resetValues={resetValues} />
            ))}
            {/* {questions.filter(q => q[0].statement.id.type === 'text').map(x => (
            <TextQuestion textQuestion = {x[0]} />
          ))} */}
            <div style={{ marginRight: '5%', marginTop:'-5%' }}>
              <TitleStyle to="#" color="inherit" variant="subtitle2" underline="none">
                Photos
              </TitleStyle>
              <ImageList sx={{ width: '100%', height: '100%' }} cols={3}>
                {formSatisfaction?.photos?.map((item, key) => (
                  <ImageListItem
                    key={key}
                    // onClick={() => {
                    //   openFullscreen(`image[${key}]`);
                    // }}
                    id={`image[${key}]`}
                  >
                    <img
                      // onClick={openFullscreen}
                      className="image expandable-image"
                      // src={`http://51.210.242.227:5001/uploads/M221332_1_10_2022/${item.name}`}
                      // src={`http://51.210.242.227:5001/uploads/${order.reference}_${new Date(order.targetDate).getDate()}_${new Date(order.targetDate).getMonth() +1}_${new Date(order.targetDate).getFullYear()}/${item.name}`}
                      src = {generatePath(order, item)}
                      alt={item.name}
                      width='250px'
                      height='250px'
                    // loading="lazy"
                    />
                  </ImageListItem>
                ))}
              </ImageList>
            </div>
            <center>
              <div name='actionButtons'>
                <Button
                  variant="contained"
                  sx={{ marginTop: '1%', marginBottom: '1%' }}
                  onClick={e => editData()}
                  disabled={disableEditButton}
                >
                  Modifier
                </Button>
                <Button variant="outlined" sx={{ marginTop: '1%', marginBottom: '1%', marginLeft: '1%' }} onClick={handleCancel}>
                  Annuler
                </Button>
              </div>
            </center>
          </DialogContent>
          </div>
        </Dialog>
      </ThemeProvider>
    </div>
  );
}
AlertDialogSlide.propTypes = {
  open: PropTypes.object.isRequired,
  setOpen: PropTypes.object.isRequired,
};

export const SliceQuestions = (data) => {
  return <Box sx={{ px: 2, py: 2, display: 'flex', justifyContent: 'space-between' }}>
    {data.questions.slice(2 * data.index, 2 * (data.index + 1)).map((x) => (
      <DataItem item={x} resetValues={data.resetValues} />
    ))
    }
  </Box>
}

export const DataItem = (item) => {
  switch (item.item[0].statement.id.type) {
    case 'raiting': return <RatingQuestion onChangeRating={(e, id, data) => onChangeRating(e, id, data)} rateQuestion={item.item[0]} resetValues={item.resetValues} />; break;
    case 'radio': return <RadioQuestion onChangeRadio={(e, id) => onChangeRadio(e, id)} radioQuestion={item.item[0]} resetValues={item.resetValues} />; break;
    case 'toggle': return <ToggleQuestion onChangeToggle={(e, id) => onChangeToggle(e, id)} toggleQuestion={item.item[0]} resetValues={item.resetValues} />; break;
    // case 'text' : return <React.Fragment /> ; break;
    case 'text': return <TextQuestion onChangeText={(e, id) => onChangeText(e, id)} textQuestion={item.item[0]} resetValues={item.resetValues} />; break;
    default: return <React.Fragment />
  }
}
