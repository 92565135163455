/* eslint-disable */
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

LogoLenovo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
  location: PropTypes.string,
};

export default function LogoLenovo(location) {
  let logo
  if(location.location === 'login'){
    logo = (
      <img style={{display: 'inline', marginBottom:'10%'}}
       src="/images/pushPull.png" alt='' width='50%' />
    )
  }
  else if (location.location === 'bonLivraison'){
    logo = (
      <img style={{display: 'flex',textAlign: 'center', margin: 'auto', marginTop:'-7%'}}
       src="/images/pushPull.png" alt='' />
    );
  }
   else {
    logo = (
      <img style={{display: 'flex',textAlign: 'center', margin: 'auto', width:'70%'}}
       src="/images/pushPull.png" alt='' />
    );
   }
   

  return <RouterLink to="/">{logo}</RouterLink>;
}
