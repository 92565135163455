/* eslint-disable */
import * as React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { createTheme, ThemeProvider, Link, styled, Typography, Grid, Button } from '@mui/material';
import './style.css';
import BlogAddCommandCard from '../blog/BlogAddCommandCard';
import AddImage from './AddImage';
import { Box } from '@mui/system';
import ExistingLivsIcon from 'src/components/livraison/ExistingLivsIcon';

// import Slide from '@mui/material/Slide';

const theme = createTheme({
  overrides: {
    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(0,0,0,0.2)',
      },
    },
  },
});

const TitleStyle = styled(Link)({
  overflow: 'hidden',
  WebkitLineClamp: 2,
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  fontWeight: 'bold',
});
export default function ExistingLivsPopup(props) {
  const { existingLivsPopup, setShowExistinglivsPopup, existingLivs } = props;

  const handleClose = () => {
    setShowExistinglivsPopup(false);
  };

  return (
    <div>
      <ThemeProvider theme={theme}>
        <Dialog
          theme={theme}
          open={existingLivsPopup}
          // TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          fullWidth
          maxWidth="md"
        >
          <DialogTitle>
            {/* <TitleStyle to="#" color="inherit" variant="subtitle2" underline="none">
              Ajouter une nouvelle commande
            </TitleStyle> */}
          </DialogTitle>
          <DialogContent>

            <div style={{ textAlign: 'center' }}>
              <Typography variant="h6" color={'#3358c9'} sx={{ marginBottom: 5, marginLeft: -7 }}>
               {existingLivs.length > 1 ? 'Les livraisons ci-dessous existent déjà' : 'La livraison ci-dessous existe déjà'} 
              </Typography>
              <Grid container spacing={-3} alignItems="center" justifyContent="center">
          {/* eslint-disable-next-line array-callback-return */}
          {existingLivs.map((item) => {
            return (
              <ExistingLivsIcon
                key={item.ref}
                title="vehicule"
                customColor="#B4C7D3"
                total={item.ref}
                icon={'fluent:document-table-truck-24-regular'}
              />
            );
          })}
        </Grid>
        <Button variant="outlined" sx={{ marginTop: '1%', marginBottom: '1%', marginLeft: '1%' }} onClick={handleClose}>
        Fermer
      </Button>
            </div>
          </DialogContent>
        </Dialog>
      </ThemeProvider>
    </div>
  );
}
ExistingLivsPopup.propTypes = {
  open: PropTypes.object.isRequired,
  setOpen: PropTypes.object.isRequired,
};
