/* eslint-disable */
import { Navigate } from "react-router-dom";

export const LOCAL_STORAGE_LOGGED_IN_KEY = 'authorized';

export const ProtectedRoute = ({ children }) => {
  const user = localStorage.getItem(LOCAL_STORAGE_LOGGED_IN_KEY);
  
  if (user === 'false') {
    // user is not authenticated
    return <Navigate to="/" />;
  }
  return children;
};