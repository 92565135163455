/* eslint-disable */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

// material
import { styled } from '@mui/material/styles';
import {
  Box,
  Link,
  Card,
  Grid,
  Button,
  Typography,
  CardContent,
  CardActions,
  Stack,
  Input,
  Autocomplete,
  createFilterOptions,
  TextField,
  Checkbox,
  createTheme,
  ThemeProvider,
  DialogTitle,
  Dialog,
  DialogContent
} from '@mui/material';
import Collapse from '@mui/material/Collapse';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import Avatar from '@mui/material/Avatar';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import { red, blue } from '@mui/material/colors';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CloseIcon from '@mui/icons-material/Close';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import moment from 'moment';
import { URL_WS } from 'src/constants';

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const TitleStyle = styled(Link)({
  // height: 44,
  overflow: 'hidden',
  WebkitLineClamp: 2,
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  fontWeight: 'bold',
});

export default function VeilleCommCard({
  VeilleComm,
}) {
  const theme = createTheme({
    overrides: {
      MuiBackdrop: {
        root: {
          backgroundColor: 'rgba(0,0,0,0.2)',
        },
      },
    },
  });

  const [showDetails, setShowDetails] = useState(false)
  const [showDeletePost, setShowDeletePost] = useState(false)
  return (
    <>
      <Grid item md="4">
        <Card sx={{ maxWidth: 345 }}>
          <CardHeader
            avatar={
              <Avatar sx={{ bgcolor: blue[500] }} aria-label="recipe">
                {VeilleComm.user?.firstName.charAt(0).toUpperCase()}
              </Avatar>
            }
            // action={
            //   <IconButton aria-label="settings">
            //     <MoreVertIcon />
            //   </IconButton>
            // }
            title={VeilleComm.title}
            subheader={`${VeilleComm.user?.firstName} ${VeilleComm.user?.lastName} - ${moment(VeilleComm.createdAt).format().substring(0, 10)}`}
          />
          <CardMedia
            component="img"
            height="194"
            // image="https://www.lenovo.com/medias/lenovo-laptop-ideapad-3-15-amd-subseries-hero.png?context=bWFzdGVyfHJvb3R8MjcxNTMxfGltYWdlL3BuZ3xoMGIvaGVlLzE0MzM5NTY0ODk2Mjg2LnBuZ3xmYWZmNDNmNjQ3MTRkY2JjNjdlZDNlOGFhNjBiNjkyYzUxMjZiMWJiMTY4Mjk3M2MyZWJlMjNlMTU1OTg4YjY1"
            image={`${URL_WS}/uploads/${VeilleComm._id}/${VeilleComm.photo}`}
            alt="Paella dish"
          />
          <CardContent>
            <Typography variant="body2" color="text.secondary" style={{ maxWidth: '300px', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
            {VeilleComm.comment}
            </Typography>
          </CardContent>
          <CardActions disableSpacing>
            <IconButton
            aria-label="share"
            onClick={e => setShowDeletePost(true)}>
              <DeleteForeverIcon />
            </IconButton>
            <IconButton
              onClick={e => setShowDetails(true)}
              style={{ position: 'absolute', right: '10px' }}
            >
              <RemoveRedEyeIcon />
            </IconButton>
          </CardActions>
        </Card>
      </Grid>
      <ThemeProvider theme={theme}>
        <Dialog
          theme={theme}
          open={showDetails}
          // TransitionComponent={Transition}
          keepMounted
          onClose={e => setShowDetails(false)}
          aria-describedby="alert-dialog-slide-description"
          fullWidth
          maxWidth="lg"
        >
          <DialogTitle>
            <IconButton
              aria-label="close"
              onClick={() => setShowDetails(false)}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <center>
                  <img src={`${URL_WS}/uploads/${VeilleComm._id}/${VeilleComm.photo}`} />
                </center>
              </Grid>
              <Grid item xs={8}>
                <div style={{ textAlign: 'center' }}>
                  <Typography variant="h6" color={'#2F95CA'} sx={{ marginBottom: 5, marginLeft: -7 }}>
                    {VeilleComm.title}
                  </Typography>
                </div>
                <div style={{ textAlign: 'center' }}>
                <Typography variant="body2" color="text.secondary">
                  {VeilleComm.comment}
                </Typography>
                </div>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </ThemeProvider>

      <ThemeProvider theme={theme}>
        <Dialog
          theme={theme}
          open={showDeletePost}
          // TransitionComponent={Transition}
          keepMounted
          onClose={e => setShowDeletePost(false)}
          aria-describedby="alert-dialog-slide-description"
          maxWidth="lg"
        >
          <DialogTitle>
            <TitleStyle to="#" color="inherit" variant="subtitle2" underline="none">
              Confirmez-vous la suppression du poste « {VeilleComm.title} » ?
            </TitleStyle>
          </DialogTitle>
          <DialogContent>
            <div style={{ textAlign: 'center' }}>
              <Button variant="contained" style={{ marginTop: '1%', marginRight: '3%' }} onClick={e => setShowDeletePost(false)}>
                Annuler
              </Button>
              <Button
                variant="contained"
                style={{ marginTop: '1%', backgroundColor: '#b02a37' }}
                onClick={e => setShowDeletePost(false)}
              >
                Supprimer
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      </ThemeProvider>
    </>
  )
}