/* eslint-disable */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

// material
import { styled } from '@mui/material/styles';
import {
  Box,
  Link,
  Card,
  Grid,
  Typography,
  CardContent,
  CardActions,
  Stack,
  Input,
  Autocomplete,
  createFilterOptions,
  TextField,
  Checkbox,
} from '@mui/material';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

// utils
import { fDate } from '../../../utils/formatTime';
import Label from '../../../components/Label';
import Iconify from '../../../components/Iconify';
import Article from '../articles/Article';
import AlertDialogSlide from '../articles/AlertDialogSlide';
import AlertConfirmDeleteCmd from '../articles/AlertConfirmDeleteCmd';
import DialogScreen from './DialogScreen';
import { jsonWebService } from 'src/infrastructure/web-service';
import * as eventsService from 'src/infrastructure/sub-pub-events/eventsSystem';
import { NOTIFICATION_TOAST_EVENT } from 'src/infrastructure/sub-pub-events/eventTypes';
import { URL_WS } from 'src/constants';
import  Translation from '../../../Translation';
// import { fShortenNumber } from '../../../utils/formatNumber';
// //
// import SvgIconStyle from '../../../components/SvgIconStyle';
// import Iconify from '../../../components/Iconify';

// ----------------------------------------------------------------------

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const TitleStyle = styled(Link)({
  height: 35,
  overflow: 'hidden',
  WebkitLineClamp: 2,
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
});

const InfoStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  // justifyContent: 'flex-end',
  marginTop: theme.spacing(4),
  color: theme.palette.text.disabled,
}));

const ExpandMore = styled((props) => {
  const { ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));
// ----------------------------------------------------------------------

BlogPostCard.propTypes = {
  item: PropTypes.object.isRequired,
  index: PropTypes.number,
};

const filter = createFilterOptions();

export default function BlogPostCard({
  item,
  index,
  commercialList,
  camionList,
  setData,
  data,
  selected,
  handleClickSelect,
  select,
  feedbackFormChanged,
  setFeedbackFormChanged,
}) {
  const [reference, setReference] = useState(item.reference);
  const [targetDate, setTargetDate] = useState(item.targetDate?.toString().substring(0, 10));
  const [vehicule, setVehicule] = useState(item.vehicule);
  const [open, setOpen] = useState(false);
  const [feedbackForm, setFeedbackForm] = useState(item.feedbackForm);
  useEffect(() => {
    setVehicule(item.vehicule);
  }, [item.vehicule]);

  useEffect(() => {
    setFeedbackForm(item.feedbackForm);
  }, [item.feedbackForm]);
  const [owner, setOwner] = useState(item.owner);
  const [ordererName, setOrdererName] = useState(item.orderer?.name);
  const [ordererPhone, setOrdererPhone] = useState(item.orderer?.phone);
  const [items, setItems] = useState(item.items);
  const [status, setStatus] = useState(item.status);
  useEffect(() => {
    setStatus(item.status);
  }, [item.status]);
  const [address1, setAddress1] = useState(item.address?.address1);
  const [address2, setAddress2] = useState(item.address?.address2);
  const [openDialog, toggleOpenDialog] = useState(false);
  const [formSatisfaction, setFormSatisfaction] = useState();
  const handleClose = () => {
    setDialogValue({
      title: '',
      year: '',
    });

    toggleOpenDialog(false);
  };
  const [dialogValue, setDialogValue] = useState({
    userName: '',
    phone: '',
  });

  const [alert, setAlert] = useState({ open: false, type: '', message: '' });

  const CloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setAlert({ open: false, type: '', message: '' });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setOwner({
      userName: dialogValue.userName,
      phone: dialogValue.phone,
    });

    handleClose();
  };

  const [expanded, setExpanded] = useState(false);
  
  const [edit, setEdit] = useState(false);
  const [editCamion, setEditCamion] = useState(false);
  const [openPopupDelete, setOpenPopupDelete] = useState(false);
  let color;

  if (status === 'delivered') {
    color = 'success';
  } else if (status === 'cancelled') {
    color = 'error';
  }
  else if(status === 'undelivered') {
    color = 'error';
  }
  else {
    color = 'warning';
  }

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleOpen = () => {
    // fetch('/mock/formSatisfaction.json', {
    //   method: 'get',
    //   headers: {
    //     'Content-Type': 'application/json',
    //     Accept: 'application/json',
    //   },
    // })
    //   .then((res) => {
    //     try {
    //       return res.json();
    //     } catch (e) {
    //       return {};
    //     }
    //   })
    //   .then((response) => {
    //     setFormSatisfaction(response.data)
    //     setOpen(true);
    //   })
    //   .catch((err) => {});
    setFormSatisfaction(feedbackForm)
    setOpen(true);
  };

  const handleOpenPopupDelete = () => {
    setOpenPopupDelete(true);
  };

  const handleEditCamion = () => {
    const url = `${URL_WS}/order/updateOrderVehicule`;
    let status = vehicule ? 'waiting' : 'pending';

    let data = { id: item._id, vehicule: vehicule, status: status };
    return new Promise((resolve, reject) => {
      jsonWebService
        .post(url, data)
        .then((response) => {
          setStatus(status);
          setEditCamion(false);
          setAlert({ open: true, type: 'success', message: 'Vehicule modifié avec succès' });
          // eventsService.publish(
          //     NOTIFICATION_TOAST_EVENT,
          //     { toastMessage: response.message, variant: response.status },
          //   );
          // this.getCommands()
        })
        .catch((err) => {});
    });
  };

  const handleCancel = () => {
    setEdit(false);
    setOrdererName(item.orderer?.name);
    setTargetDate(item.targetDate);
    setReference(item.reference);
    setAddress1(item.address?.address1);
    setAddress2(item.address?.address2);
    setOrdererPhone(item.orderer?.phone);
    setOwner(item.owner);
    setVehicule(item.vehicule);
  };

  const handleSave = () => {
    let status = vehicule ? 'waiting' : 'pending';
    let data = {
      targetDate: targetDate,
      reference: reference,
      status: status,
      vehicule: vehicule,
      owner: owner,
      orderer: {
        name: ordererName,
        phone: ordererPhone,
      },
      address: {
        address1: address1,
        address2: address2,
      },
    };
    jsonWebService
      .post(`${URL_WS}/order/update/${item._id}`, data)
      .then((response) => {
        setEdit(false);
        setStatus(status);
        setAlert({ open: true, type: 'success', message: 'Commande modifiée avec succès' });
      })
      .catch((err) => {});
  };

  const handleDelete = () => {
    jsonWebService
      .get(`${URL_WS}/order/remove/${item._id}`)
      .then((response) => {
        setAlert({ open: true, type: 'success', message: 'Commande supprimée avec succès' });
        setData(data.filter((order) => order._id != item._id));
        setOpenPopupDelete(false);
      })
      .catch((err) => {});
  };

  return (
    <Grid item md="6">
      <Card sx={{ position: 'relative' }}>
        {!edit ? (
          <>
            <InfoStyle>
              {select && <Checkbox checked={selected} onChange={(event) => handleClickSelect(event, item._id)} />}

              <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2} ml={2}>
                <Typography
                  gutterBottom
                  variant="caption"
                  sx={{
                    color: 'text.disabled',
                    position: 'absolute',
                    right: 10,
                    cursor: 'pointer',
                  }}
                >
                  {targetDate ? fDate(targetDate) : ''}
                </Typography>
                {
                  status ==='waiting' ?
                  <Label variant="ghost" color={color} sx={{backgroundColor:'#F4D6B5', color:'#E98821'}}>
                 <Translation message= {status} />
                </Label>
                :
                  <Label variant="ghost" color={color}>
                    <Translation message= {status} />
                  </Label>
                }
                

                {status === 'delivered' ? (
                  <Iconify
                    icon="eva:file-text-outline"
                    sx={{
                      width: 16,
                      height: 16,
                      mr: 1.5,
                      cursor: 'pointer',
                      '&:hover': {
                        color: 'darkBlue',
                      },
                    }}
                    onClick={handleOpen}
                  />
                ) : (
                  <>
                    <CustomIcon edit={edit} setEdit={setEdit} />
                    <Iconify
                      icon="eva:trash-2-outline"
                      style={{ cursor: 'pointer' }}
                      sx={{ mr: 1.5, float: 'right', marginTop: '1%', marginBottom: '1%', marginLeft: '10%' }}
                      onClick={handleOpenPopupDelete}
                    />
                  </>
                )}
              </Stack>
            </InfoStyle>
            <CardContent sx={{ marginTop: '1%' }}>
              {/* <ExpandMore onClick={handleExpandClick} aria-expanded={expanded} aria-label="show more"> */}
              <TitleStyle
                to="#"
                color="inherit"
                variant="subtitle2"
                underline="none"
                component={RouterLink}
                sx={{
                  color: '#2f4858',
                  display: 'block',
                }}
              >
                <Iconify icon="eva:person-outline" sx={{ width: 16, height: 16, mr: 1.5 }} />
                {ordererName}
              </TitleStyle>
              <TitleStyle
                to="#"
                color="inherit"
                variant="subtitle2"
                underline="none"
                component={RouterLink}
                sx={{
                  color: '#2f4858',
                  display: 'block',
                }}
              >
                <Iconify icon="eva:shopping-cart-outline" sx={{ width: 16, height: 16, mr: 1.5 }} />
                {reference}
              </TitleStyle>

              <Typography gutterBottom variant="caption" sx={{ color: '#2f4858', display: 'block', marginTop: '3%' }}>
                <Iconify icon="eva:home-outline" sx={{ width: 16, height: 16, mr: 1.5 }} />
                {address1}
              </Typography>
              <Typography gutterBottom variant="caption" sx={{ color: '#2f4858', display: 'block' }}>
                <Iconify icon="eva:home-outline" sx={{ width: 16, height: 16, mr: 1.5 }} />
                {address2}
              </Typography>
              <Typography gutterBottom variant="caption" sx={{ display: 'block' }}>
                <Iconify icon="eva:phone-outline" sx={{ width: 16, height: 16, mr: 1.5 }} />
                {ordererPhone}
              </Typography>
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Typography gutterBottom variant="caption" sx={{ display: 'block' }}>
                  <Iconify icon="bx:user-voice" sx={{ width: 16, height: 16, mr: 1.5 }} />
                  {owner?.userName}
                </Typography>
                {!editCamion ? (
                  <Typography gutterBottom variant="caption" sx={{ display: 'block' }}>
                    <Iconify
                      icon="eva:car-outline"
                      sx={{
                        width: 16,
                        height: 16,
                        mr: 1.5,
                        '&:hover': {
                          cursor: 'pointer',
                          color: 'gray',
                        },
                      }}
                      onClick={() => {
                        setEditCamion(true);
                      }}
                    />
                    {vehicule?.registrationNb}
                  </Typography>
                ) : (
                  <div
                    style={{
                      color: '#2f4858',
                      display: 'flex',
                    }}
                  >
                    <Iconify
                      icon="eva:save-outline"
                      width={20}
                      height={20}
                      color="darkBlue"
                      style={{ cursor: 'pointer' }}
                      onClick={handleEditCamion}
                    />
                    <Iconify
                      icon="eva:close-circle-outline"
                      width={20}
                      height={20}
                      color="red"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setEditCamion(false);
                        setVehicule(item.vehicule);
                      }}
                    />

                    <Autocomplete
                      id="combo-box-demo"
                      options={camionList}
                      value={vehicule?.registrationNb}
                      onChange={(event, newValue) => {
                        setVehicule(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          size="small"
                          sx={{ width: '200px', display: 'inline-block', overflow: 'hidden' }}
                        />
                      )}
                    />
                  </div>
                )}
              </Box>
            </CardContent>
            <CardActions disableSpacing>
              <ExpandMore expand={expanded} onClick={handleExpandClick} aria-expanded={expanded} aria-label="show more">
                <Iconify icon="eva:arrow-ios-downward-outline" sx={{ width: 16, height: 16 }} />
              </ExpandMore>
            </CardActions>
          </>
        ) : (
          <>
            <InfoStyle>
              <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2} ml={2}>
                {/* <Input
                  type="date"
                  value={new Date(targetDate)}
                  sx={{
                    position: 'absolute',
                    right: 10,
                  }}
                /> */}
                <Input
                  type="date"
                  onChange={(e) => setTargetDate(e.target.value)}
                  value={targetDate}
                  // value={
                  //   (new Date(targetDate).getMonth() + 1).toString().length < 2
                  //     ? new Date(targetDate).getDate().toString().length < 2
                  //       ? `${new Date(targetDate).getFullYear()}-0${new Date(targetDate).getMonth() + 1}-0${new Date(
                  //           targetDate
                  //         ).getDate()}`
                  //       : `${new Date(targetDate).getFullYear()}-0${new Date(targetDate).getMonth() + 1}-${new Date(
                  //           targetDate
                  //         ).getDate()}`
                  //     : new Date(targetDate).getDate().toString().length < 2
                  //     ? `${new Date(targetDate).getFullYear()}-${new Date(targetDate).getMonth() + 1}-0${new Date(
                  //         targetDate
                  //       ).getDate()}`
                  //     : `${new Date(targetDate).getFullYear()}-${new Date(targetDate).getMonth() + 1}-${new Date(
                  //         targetDate
                  //       ).getDate()}`
                  // }
                  sx={{
                    position: 'absolute',
                    right: 10,
                  }}
                />
                <Label variant="ghost" color={color}>
                  {status}
                </Label>
                {status === 'delivered' ? (
                  <Iconify
                    icon="eva:file-text-outline"
                    sx={{
                      width: 16,
                      height: 16,
                      mr: 1.5,
                      cursor: 'pointer',
                      '&:hover': {
                        color: 'darkBlue',
                      },
                    }}
                    onClick={handleOpen}
                  />
                ) : (
                  <CustomIcon edit={edit} setEdit={setEdit} handleCancel={handleCancel} handleSave={handleSave} />
                )}
              </Stack>
            </InfoStyle>
            <CardContent>
              <div
                sx={{
                  color: '#2f4858',
                  display: 'block',
                }}
              >
                <Iconify icon="eva:person-outline" sx={{ width: 16, height: 16, mr: 1.5 }} />
                <Input
                  type="text"
                  value={ordererName}
                  onChange={(e) => {
                    setOrdererName(e.target.value);
                  }}
                />
              </div>
              <div
                sx={{
                  color: '#2f4858',
                  display: 'block',
                }}
              >
                <Iconify icon="eva:shopping-cart-outline" sx={{ width: 16, height: 16, mr: 1.5 }} />
                <Input
                  type="text"
                  value={reference}
                  onChange={(e) => {
                    setReference(e.target.value);
                  }}
                />
              </div>

              <div
                style={{
                  color: '#2f4858',
                  display: 'block',
                  marginTop: '3%',
                }}
              >
                <Iconify icon="eva:home-outline" sx={{ width: 16, height: 16, mr: 1.5 }} />
                <Input
                  type="text"
                  value={address1}
                  onChange={(e) => {
                    setAddress1(e.target.value);
                  }}
                />
              </div>
              <div
                style={{
                  color: '#2f4858',
                  display: 'block',
                }}
              >
                <Iconify icon="eva:home-outline" sx={{ width: 16, height: 16, mr: 1.5 }} />
                <Input
                  type="text"
                  value={address2}
                  onChange={(e) => {
                    setAddress2(e.target.value);
                  }}
                />
              </div>
              <div
                style={{
                  color: '#2f4858',
                  display: 'block',
                }}
              >
                <Iconify icon="eva:phone-outline" sx={{ width: 16, height: 16, mr: 1.5 }} />

                <Input
                  type="text"
                  value={ordererPhone}
                  onChange={(e) => {
                    setOrdererPhone(e.target.value);
                  }}
                />
              </div>
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <div
                  style={{
                    color: '#2f4858',
                    display: 'flex',
                  }}
                >
                  <Iconify icon="bx:user-voice" sx={{ width: 16, height: 16, mr: 1.5 }} />

                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={commercialList}
                    value={owner?.userName}
                    renderInput={(params) => (
                      <TextField {...params} variant="standard" size="small" sx={{ width: '200px' }} />
                    )}
                    // onChange={(event, newValue) => {
                    //   setOwner(newValue);
                    // }}
                    onChange={(event, newValue) => {
                      if (typeof newValue === 'string') {
                        // timeout to avoid instant validation of the dialog's form.
                        setTimeout(() => {
                          toggleOpenDialog(true);
                          setDialogValue({
                            userName: newValue,
                            phone: '',
                          });
                        });
                      } else if (newValue && newValue.inputValue) {
                        toggleOpenDialog(true);
                        setDialogValue({
                          userName: newValue.inputValue,
                          year: '',
                        });
                      } else {
                        setOwner(newValue);
                      }
                    }}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);

                      if (params.inputValue !== '') {
                        filtered.push({
                          inputValue: params.inputValue,
                          userName: `Add "${params.inputValue}"`,
                        });
                      }

                      return filtered;
                    }}
                    getOptionLabel={(option) => {
                      // e.g value selected with enter, right from the input
                      if (typeof option === 'string') {
                        return option;
                      }
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      return option.userName;
                    }}
                  />
                </div>

                <div
                  style={{
                    color: '#2f4858',
                    display: 'flex',
                  }}
                >
                  <Iconify icon="eva:car-outline" sx={{ width: 16, height: 16, mr: 1.5 }} />

                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={camionList}
                    value={vehicule?.registrationNb}
                    renderInput={(params) => (
                      <TextField {...params} variant="standard" size="small" sx={{ width: '200px' }} />
                    )}
                    onChange={(event, newValue) => {
                      setVehicule(newValue);
                    }}
                  />
                </div>
              </Box>
            </CardContent>
            <CardActions disableSpacing>
              <ExpandMore expand={expanded} onClick={handleExpandClick} aria-expanded={expanded} aria-label="show more">
                <Iconify icon="eva:arrow-ios-downward-outline" sx={{ width: 16, height: 16 }} />
              </ExpandMore>
            </CardActions>
          </>
        )}
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            {/* <Typography paragraph>Articles</Typography> */}
            <Article status={status} items={items} setItems={setItems} idOrder={item._id} />
          </CardContent>
        </Collapse>
      </Card>
      <AlertDialogSlide open={open} setOpen={setOpen} order={item} formSatisfaction= {formSatisfaction} feedbackFormChanged={feedbackFormChanged} setFeedbackFormChanged={setFeedbackFormChanged} />
      <AlertConfirmDeleteCmd
        openPopupDelete={openPopupDelete}
        setOpenPopupDelete={setOpenPopupDelete}
        reference={reference}
        handleDelete={handleDelete}
      />
      <DialogScreen
        openDialog={openDialog}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        dialogValue={dialogValue}
        setDialogValue={setDialogValue}
      />
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={alert.open}
        autoHideDuration={3000}
        onClose={CloseAlert}
      >
        <Alert onClose={CloseAlert} severity={alert.type} sx={{ width: '100%' }}>
          {alert.message}
        </Alert>
      </Snackbar>
    </Grid>
  );
}

export function CustomIcon(props) {
  const { edit, setEdit, handleCancel, handleSave } = props;

  const handleEdit = (boolVal) => {
    setEdit(boolVal);
  };

  if (!edit) {
    return (
      <Iconify
        icon="eva:edit-2-outline"
        sx={{
          width: 16,
          height: 16,
          mr: 1.5,
          cursor: 'pointer',
          '&:hover': {
            color: 'darkBlue',
          },
        }}
        onClick={() => {
          handleEdit(true);
        }}
      />
    );
  }
  // eslint-disable-next-line no-else-return
  else {
    return (
      <>
        <Iconify
          icon="eva:save-outline"
          width={20}
          height={20}
          color="darkBlue"
          style={{ cursor: 'pointer' }}
          onClick={handleSave}
        />
        <Iconify
          icon="eva:close-circle-outline"
          width={20}
          height={20}
          color="red"
          style={{ cursor: 'pointer' }}
          onClick={handleCancel}
        />
      </>
    );
  }
}
