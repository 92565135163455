/* eslint-disable */
import * as React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { createTheme, ThemeProvider, Link, styled, Typography } from '@mui/material';
import './style.css';
import BlogAddCommandCard from '../blog/BlogAddCommandCard';
import AddImage from './AddImage';
import { Box } from '@mui/system';

// import Slide from '@mui/material/Slide';

const theme = createTheme({
  overrides: {
    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(0,0,0,0.2)',
      },
    },
  },
});

const TitleStyle = styled(Link)({
  overflow: 'hidden',
  WebkitLineClamp: 2,
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  fontWeight: 'bold',
});
export default function AddCommandForm(props) {
  const { showAddCommandForm, setShowAddCommandForm, commercialList, camionList } = props;

  const handleClose = () => {
    setShowAddCommandForm(false);
  };

  return (
    <div>
      <ThemeProvider theme={theme}>
        <Dialog
          theme={theme}
          open={showAddCommandForm}
          // TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          fullWidth
          maxWidth="lg"
        >
          <DialogTitle>
            {/* <TitleStyle to="#" color="inherit" variant="subtitle2" underline="none">
              Ajouter une nouvelle commande
            </TitleStyle> */}
          </DialogTitle>
          <DialogContent>
            <Box>
              <AddImage />
            </Box>

            <div style={{ textAlign: 'center' }}>
              <Typography variant="h6" color={'#3358c9'} sx={{ marginBottom: 5, marginTop: -12, marginLeft: -7 }}>
                Créer une nouvelle commande
              </Typography>
              <BlogAddCommandCard
                commercialList={commercialList}
                camionList={camionList}
                setShowAddCommandForm={setShowAddCommandForm}
                handleClose={handleClose}
              />
            </div>
          </DialogContent>
        </Dialog>
      </ThemeProvider>
    </div>
  );
}
AddCommandForm.propTypes = {
  open: PropTypes.object.isRequired,
  setOpen: PropTypes.object.isRequired,
};
