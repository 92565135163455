/* eslint-disable */
import { filter } from 'lodash';
import { useEffect, useState } from 'react';
// material
import {
  Card,
  Table,
  Stack,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Input,
  TextField,
  Autocomplete,
} from '@mui/material';
// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import { jsonWebService } from 'src/infrastructure/web-service';
import { URL_WS } from 'src/constants';
import CarListHead from 'src/sections/@dashboard/vente/VenteListHead';
import AlertConfirmDeleteCar from 'src/sections/@dashboard/vente/AlertConfirmDeleteCar';
import AlertConfirmDeleteCars from 'src/sections/@dashboard/vente/AlertConfirmDeleteCars';

import moment from 'moment';
import ObjectifListToolbar from 'src/sections/@dashboard/objectif/ObjectifListToolbar';
import AddObjectifForm from 'src/sections/@dashboard/objectif/AddObjectifForm';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { fr } from 'date-fns/locale';
import * as eventsService from 'src/infrastructure/sub-pub-events/eventsSystem';
import { NOTIFICATION_TOAST_EVENT } from 'src/infrastructure/sub-pub-events/eventTypes';
import Translation from 'src/Translation';
import AlertConfirmDeleteObj from 'src/sections/@dashboard/objectif/AlertConfirmDeleteObj';
import AlertConfirmDeleteObjectifs from 'src/sections/@dashboard/objectif/AlertConfirmDeleteObjectifs';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'space', label: 'Point de vente', alignRight: false },
  { id: 'month', label: 'Mois', alignRight: false },
  { id: 'category', label: 'Catégorie', alignRight: false },
  { id: 'number', label: 'Nombre', alignRight: false },
  { id: 'action', label: 'Action', alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortSpaceFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (objectif) => objectif.assignment?.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function Objectif() {
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [objAssignment, setObjAssignment] = useState('');

  const [objStarDate, setObjStarDate] = useState(new Date());

  const [objClassification, setObjClassification] = useState('');

  const [objObjectif, setObjObjectif] = useState('');

  const [objectifsList, setObjectifsList] = useState([])

  const [spaceList, setSpaceList] = useState([])

  const [filtredObjectifsList, setFiltredObjectifsList] = useState([])

  const [filterSpace, setFilterSpace] = useState('');

  const [filterMonth, setFilterMonth] = useState(null);

  const [editableRow, setEditableRow] = useState(undefined);

  const [openPopupDeleteObj, setOpenPopupDeleteObj] = useState(false);

  const [openPopupDeleteObjectifs, setOpenPopupDeleteObjectifs] = useState(false);

  const [objToDelete, setObjToDelete] = useState('');

  const [showAddObjectifForm, setShowAddObjectifForm] = useState(false);

  const listCategorie = [
    {
      label: 'CONS-LOW', value: 'CONS-LOW'
    },
    {
      label: 'CONS-MID', value: 'CONS-MID'
    },
    {
      label: 'CONS-HIGH', value: 'CONS-HIGH'
    },
    {
      label: 'COM-LOW', value: 'COM-LOW'
    },
    {
      label: 'COM-MID', value: 'COM-MID'
    },
    {
      label: 'COM-HIGH', value: 'COM-HIGH'
    }

  ]

  const openAddObjectifForm = () => {
    setShowAddObjectifForm(true);
  };

  const handleOpenPopupDeleteObj = (row) => {
    setObjToDelete(row)
    setOpenPopupDeleteObj(true);
  };

  const handleOpenPopupDeleteObjectifs = (row) => {
    setOpenPopupDeleteObjectifs(true);
  };

  const editRow = (row) => {
    setEditableRow(row._id);
    setObjAssignment(row.assignment?.name);
    setObjStarDate(row.starDate);
    setObjClassification(row.classification);
    setObjObjectif(row.objectif);
  };

  const canselEditRow = () => {
    setEditableRow(undefined);
  };

  const handleUpdate = () => {
    let data = {
      starDate: moment(objStarDate).startOf('month'),
      endDate: moment(objStarDate).endOf('month'),
      assignment: objAssignment.value,
      classification: objClassification.value,
      objectif: objObjectif
    };
    jsonWebService
      .post(`${URL_WS}/objectif/update/${editableRow}`, data)
      .then((response) => {
        setEditableRow(undefined);
        getObjectifs();
        eventsService.publish(NOTIFICATION_TOAST_EVENT, { toastMessage: <Translation message= {response.message} />, variant: response.status });

      })
      .catch((err) => { });
  };


  useEffect(() => {
    jsonWebService
      .get(`${URL_WS}/objectif/getAll`)
      .then((response) => {
        setObjectifsList(response.data);
        setFiltredObjectifsList(response.data)
      })
      .catch((err) => {
      });
  }, []);

  useEffect(() => {
    let list = []
    jsonWebService
      .get(`${URL_WS}/espace/getAll`)
      .then((response) => {
        response.data?.map(r => {
          let esp = { label: r.name, value: r._id }
          list.push(esp)
        })
        setSpaceList(list)
      })
      .catch((err) => { });
  }, []);

  useEffect(() => {
    let filtredTable = objectifsList
    if (filterSpace !== '') {
      filtredTable = applySortSpaceFilter(filtredTable, getComparator(order, orderBy), filterSpace);
    }
    if (filterMonth !== null) {
      filtredTable = filtredTable.filter(x =>
        new Date(moment(x.starDate).format().substring(0, 10)).getMonth() === filterMonth.getMonth())
    }
    setFiltredObjectifsList(filtredTable)
  }, [filterSpace, filterMonth]);

  const getObjectifs = () => {
    jsonWebService
      .get(`${URL_WS}/objectif/getAll`)
      .then((response) => {
        setObjectifsList(response.data);
        setFiltredObjectifsList(response.data)
      })
      .catch((err) => { });
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = filtredObjectifsList.map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, label) => {
    const selectedIndex = selected.indexOf(label);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, label);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterBySpace = (event) => {
    setFilterSpace(event.target.value);
  };

  const tabMonths = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"]

  return (
    <Page title="Objectifs">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Objectifs
          </Typography>
          <Button variant="contained" onClick={openAddObjectifForm} startIcon={<Iconify icon="eva:plus-fill" />}>
            Nouveau Objectif
          </Button>
        </Stack>

        <Card>
          <ObjectifListToolbar
            numSelected={selected.length}
            filterSpace={filterSpace}
            onFilterSpace={handleFilterBySpace}
            filterMonth={filterMonth}
            onFilterMonth={setFilterMonth}
            handleOpenPopupDeleteObjectifs={handleOpenPopupDeleteObjectifs} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <CarListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={filtredObjectifsList.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                  useCheckBox
                />
                <TableBody>
                  {filtredObjectifsList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const { _id, assignment, classification, starDate, objectif } = row;
                    const isItemSelected = selected.indexOf(_id) !== -1;
                    if (editableRow === row._id && row._id) {
                      return (
                        <TableRow
                          hover
                          key={_id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, name)} />
                          </TableCell>
                          <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              {/* <Avatar alt={name} src={avatarUrl} /> */}
                              <Autocomplete
                                
                                id="combo-box-demo"
                                options={spaceList}
                                value={objAssignment}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="standard"
                                    size="small"
                                    sx={{ width: '200px' }}
                                    placeholder="Point de vente"
                                  />
                                )}
                                onChange={(event, newValue) => {
                                  setObjAssignment(newValue)
                                }}
                              />
                            </Stack>
                          </TableCell>
                          <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <LocalizationProvider dateAdapter={AdapterDateFns} locale={fr}>
                                <DatePicker
                                  views={['month']}
                                  inputFormat="MMMM"
                                  label=""
                                  value={objStarDate}
                                  onChange={(newValue) => {
                                    setObjStarDate(newValue);
                                  }}
                                  renderInput={(params) => <TextField {...params} helperText={null} />}
                                />
                              </LocalizationProvider>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">
                            <Autocomplete
                              id="combo-box-demo"
                              options={listCategorie}
                              value={objClassification}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="standard"
                                  size="small"
                                  sx={{ width: '200px' }}
                                  placeholder="Catégorie"
                                />
                              )}
                              onChange={(event, newValue) => {
                                setObjClassification(newValue)
                              }}
                            />
                          </TableCell>
                          <TableCell align="left">
                            <Input type="text" required="true" value={objObjectif} onChange={(e) => setObjObjectif(e.target.value)} />
                          </TableCell>
                          <TableCell align="left">
                            <Iconify
                              icon="eva:save-outline"
                              width={20}
                              height={20}
                              color="darkBlue"
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                handleUpdate();
                              }}
                            />
                            <Iconify
                              icon="eva:close-circle-outline"
                              width={20}
                              height={20}
                              color="red"
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                canselEditRow();
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    }
                    else {
                      return (
                        <TableRow
                          hover
                          key={_id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, _id)} />
                          </TableCell>
                          <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              {/* <Avatar alt={name} src={avatarUrl} /> */}
                              <Typography variant="subtitle2" noWrap>
                                {assignment?.name}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              {/* <Avatar alt={name} src={avatarUrl} /> */}
                              <Typography variant="subtitle2" noWrap>
                                {tabMonths[new Date(moment(starDate).format().substring(0, 10)).getMonth()]}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              {/* <Avatar alt={name} src={avatarUrl} /> */}
                              <Typography variant="subtitle2" noWrap>
                                {classification}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">{objectif}</TableCell>
                          <TableCell align="left">
                            <Iconify
                              icon="eva:edit-2-outline"
                              width={20}
                              height={20}
                              color="gray"
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                editRow(row);
                              }}
                            />
                            <Iconify
                              icon="eva:trash-2-outline"
                              width={20}
                              height={20}
                              color="gray"
                              style={{ cursor: 'pointer' }}
                              onClick={e => handleOpenPopupDeleteObj(row)}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    }
                  })}
                </TableBody>

                {/* {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterMatricule} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )} */}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filtredObjectifsList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
        <AlertConfirmDeleteObj
          openPopupDeleteObj={openPopupDeleteObj}
          setOpenPopupDeleteObj={setOpenPopupDeleteObj}
          objToDelete={objToDelete}
          getObjectifs={getObjectifs}
        />
        <AlertConfirmDeleteObjectifs
          openPopupDeleteObjectifs={openPopupDeleteObjectifs}
          setOpenPopupDeleteObjectifs={setOpenPopupDeleteObjectifs}
          selectedObjectifs={selected}
          getObjectifs={getObjectifs}
          setSelected={setSelected}
        />
        <AddObjectifForm
          showAddObjectifForm={showAddObjectifForm}
          setShowAddObjectifForm={setShowAddObjectifForm}
          getObjectifs={getObjectifs}
        />
      </Container>
    </Page>
  );
}
