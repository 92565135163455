/* eslint-disable */
import { filter } from 'lodash';
import React, { useEffect, useState } from 'react';
// material
import {
  Stack,
  Button,
  Container,
  Switch,
  Typography,
  TextField,
  CircularProgress,
} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
// components
import Page from '../components/Page';
import Iconify from '../components/Iconify';
import { UserListToolbar } from 'src/sections/@dashboard/user';
import { jsonWebService } from 'src/infrastructure/web-service';
import { URL_WS } from 'src/constants';
import moment from 'moment';
import ReactMapboxGl, { Layer, Feature } from 'react-mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import Map from 'src/components/Map';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

// ----------------------------------------------------------------------
const useStyles = theme =>({
  
  buttonExport: {
    backgroundColor: '#2F95CA',
    color: '#fff',
    border: '0',
    minWidth:'64px',
    padding:'6px 16px',
    borderRadius:'8px',
    fontFamily:'Public Sans,sans-serif',
    fontSize:'0.875rem',
    fontWeight:'700',
    cursor:'pointer',
    boxShadow:'0 8px 16px 0 rgb(47 149 202 / 24%)',
    lineHeight:'1.7142857142857142',
    '&:hover': {
      backgroundColor: '#2F95CA',
      color: '#fff',
      borderColor:'#2F95CA'
  },
  
},})

class Locations extends React.Component {
  state= {
    usersList: [],
    workDate: new Date(),
    loader: false
  }

  changeTabsView(){
    const {viewMode} = this.state
    if(viewMode === 'defaultt'){
      this.setState({ viewMode : 'tabs' })
    }
    else {
      this.setState({ viewMode : 'defaultt' })
    }
  }

  componentDidMount(){
    const { workDate } = this.state
  this.fetchData()    
  }

  fetchData(WD){
    let listUser = []
    this.setState({ loader : true })
    jsonWebService
      .get(`${URL_WS}/calendar/getAll`)
      .then((response) => {
        
        response.data.map(r => {
          if(r.positions.length > 0) {
            let userWithPosition = {
              userDetails: r.user,
              positions: r.positions,
              WorkDate: r.WorkDate
            }
            listUser.push(userWithPosition)
          }
        })
        let filtredData = []
        listUser.map(f =>{
          if(moment(f.WorkDate).format("YYYY/MM/DD") === moment(WD).format("YYYY/MM/DD")){
            filtredData.push(f)
          }
        } )
        this.setState({
          usersList: filtredData,
          loader: false
        })
        
      })
      .catch((err) => { });
  }

  changeDate(e){
    this.setState({ workDate : e })
    this.fetchData(e)
  }

  render() {
    const { usersList, workDate, loader } = this.state
    // const Maps = ReactMapboxGl({
    //   accessToken: "pk.eyJ1IjoicmF3ZW5tZXJzYW5pIiwiYSI6ImNsNmF6ejFsYTBtZXYzanFmcXp6MHJoZnUifQ.w144brpzhoKqRv9MODG5rQ",
      
    // });

        return (
          <Page title="Locations" >
            <Container>
              <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
              label="Date"
              inputFormat="dd/MM/yyyy"
              onChange={e => this.changeDate(e)}
              value={workDate}
              renderInput={(params) => <TextField {...params} />} />
            </LocalizationProvider>
              </Stack>
              {/* <Maps
  style="mapbox://styles/mapbox/streets-v9"
  containerStyle={{
    height: '100vh',
    width: '100vw'
  }}
  
>
  <Layer type="symbol" id="marker" layout={{ 'icon-image': 'marker-15' }}>
    <Feature coordinates={[36.806389, 10.181667]} />
  </Layer>
</Maps> */}
{loader ? 
<CircularProgress />
:
<></>
}
<Map country='Tunisia' usersPositions={usersList} />
              </Container>
          </Page>
        );
    }
    
    
  }

export default withStyles(useStyles) (Locations)
