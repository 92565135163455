/* eslint-disable */
import { filter } from 'lodash';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Input,
  MenuItem,
  TextField,
} from '@mui/material';
// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import { UserListHead } from '../sections/@dashboard/user';
// mock
import ArticleListToolbar from 'src/sections/@dashboard/article/ArticleListToolbar';
import { useEffect } from 'react';
import { jsonWebService } from 'src/infrastructure/web-service';
import { URL_WS } from 'src/constants';
import AddArticleForm from 'src/sections/@dashboard/article/AddArticleForm';
import * as eventsService from 'src/infrastructure/sub-pub-events/eventsSystem';
import { NOTIFICATION_TOAST_EVENT } from 'src/infrastructure/sub-pub-events/eventTypes';
import Translation from 'src/Translation';
import AlertConfirmDeleteArticle from 'src/sections/@dashboard/article/AlertConfirmDeleteArticle';
import AlertConfirmDeleteArticles from 'src/sections/@dashboard/article/AlertConfirmDeleteArticles';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'reference', label: 'Reference', alignRight: false },
  { id: 'designation', label: 'Processeur', alignRight: false },
  { id: 'category', label: 'Catégorie', alignRight: false },
  { id: 'price', label: 'Prix', alignRight: false },
  { id: 'action', label: 'Action', alignRight: false},
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_article) => _article.reference.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

function applySortFilterProcessor(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_article) => _article.description.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

function applySortFilterClassification(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_article) => _article.classification.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function Articles() {
  
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [articleReference, setArticleReference] = useState('');

  const [articleProcessor, setArticleProcessor] = useState('');

  const [articleClassification, setArticleClassification] = useState('Toutes les catégories');

  const [ articleRefEdit, setArticleRefEdit ]= useState('');

  const [ articleProcessorEdit, setArticleProcessorEdit ]= useState('');

  const [ articleCategoryEdit, setArticleCategoryEdit ]= useState('');

  const [ articlePriceEdit, setArticlePriceEdit ]= useState('');

  const [editableRow, setEditableRow] = useState(undefined);

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [ articleList, setArticleList ] = useState([])

  const [ filteredArticles, setFilteredArticles] = useState([])

  const [showAddArticleForm, setShowAddArticleForm] = useState(false);

  const [openPopupDeleteArticle, setOpenPopupDeleteArticle] = useState(false);

  const [articleToDelete, setArticleToDelete] = useState('');

  const [openPopupDeleteArticles, setOpenPopupDeleteArticles] = useState(false);

  const handleOpenPopupDeleteArticles = (row) => {
    setOpenPopupDeleteArticles(true);
  };

  const handleOpenPopupDeleteArticle = (row) => {
    setArticleToDelete(row)
    setOpenPopupDeleteArticle(true);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = articleList.map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByReference = (event) => {
    setArticleReference(event.target.value);
  };

  const handleFilterByClassification = (event) => {
    setArticleClassification(event.target.value);
  };

  const handleFilterByProcessor = (event) => {
    setArticleProcessor(event.target.value)
  }

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - articleList.length) : 0;

  const openAddArticleForm = () => {
    setShowAddArticleForm(true);
  };

  const editRow = (row) => {
    setEditableRow(row._id);
    setArticleRefEdit(row.reference)
    setArticleProcessorEdit(row.description)
    setArticleCategoryEdit(row.classification)
    setArticlePriceEdit(row.currentPrice)
  };

  const canselEditRow = () => {
    setEditableRow(undefined);
  };

  const handleUpdate = () => {
    let data = {
      reference: articleRefEdit,
      description: articleProcessorEdit,
      classification: articleCategoryEdit,
      currentPrice: articlePriceEdit
    };
    // console.log('data :', data);
    jsonWebService
      .post(`${URL_WS}/article/update/${editableRow}`, data)
      .then((response) => {
        setEditableRow(undefined);
        getArticles()
        eventsService.publish(NOTIFICATION_TOAST_EVENT, { toastMessage: <Translation message= {response.message} />, variant: response.status });
      })
      .catch((err) => { });
  };

  useEffect(() => {
    jsonWebService
      .get(`${URL_WS}/article/getAll`)
      .then((response) => {
        setArticleList(response.data)
        setFilteredArticles(response.data)
      })
      .catch((err) => {
      });
  }, []);

  useEffect(() => {
    let filtredTable = articleList
    if(articleReference !== ''){
      filtredTable = applySortFilter(filtredTable, getComparator(order, orderBy), articleReference);
    }

    if(articleProcessor !== ''){
      filtredTable = applySortFilterProcessor(filtredTable, getComparator(order, orderBy), articleProcessor);
    }

    if(articleClassification !== 'Toutes les catégories'){
      filtredTable = applySortFilterClassification(filtredTable, getComparator(order, orderBy), articleClassification);
    }

    setFilteredArticles(filtredTable)
  }, [articleReference, articleProcessor, articleClassification]);

  const getArticles = () => {
    jsonWebService
      .get(`${URL_WS}/article/getAll`)
      .then((response) => {
        setArticleList(response.data)
        setFilteredArticles(response.data)
      })
      .catch((err) => {});
  }

  return (
    <Page title="Articles">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Articles
          </Typography>
          <Button variant="contained" onClick={openAddArticleForm} component={RouterLink} to="#" startIcon={<Iconify icon="eva:plus-fill" />}>
            Nouvel article
          </Button>
        </Stack>

        <Card>
          <ArticleListToolbar numSelected={selected.length}
          filterArticleReference={articleReference}
          onFilterReference={handleFilterByReference} 
          filterArticleProcessor= {articleProcessor}
          onFilterProcessor= {handleFilterByProcessor} 
          filterArticleClassification= {articleClassification}
          onFilterClassification= {handleFilterByClassification}
          handleOpenPopupDeleteArticles= {handleOpenPopupDeleteArticles}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={articleList.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                  useCheckBox
                />
                <TableBody>
                  {filteredArticles.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const { _id, description, reference, designation, classification, currentPrice } = row;
                    const isItemSelected = selected.indexOf(_id) !== -1;
                    if (editableRow === row._id && row._id) {
                      return(
                        <TableRow
                        hover
                        key={_id}
                        tabIndex={-1}
                        email="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, _id)} />
                        </TableCell>
                        <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            {/* <Avatar alt={name} src={avatarUrl} /> */}
                            <Input type="text" required="true" value={articleRefEdit} onChange={(e) => setArticleRefEdit(e.target.value)} />
                          </Stack>
                        </TableCell>
                        <TableCell align="left">
                        <Input type="text" required="true" value={articleProcessorEdit} onChange={(e) => setArticleProcessorEdit(e.target.value)} />
                        </TableCell>
                        <TableCell align="left">
                        <TextField
                              select
                              size="small"
                              value={articleCategoryEdit}
                              onChange={e => setArticleCategoryEdit(e.target.value)}
                            >
                              <MenuItem key='CONS-LOW' value='CONS-LOW'>
                              CONS-LOW
                              </MenuItem>
                              <MenuItem key='CONS-MID' value='CONS-MID'>
                                CONS-MID
                              </MenuItem>
                              <MenuItem key='CONS-HIGH' value='CONS-HIGH'>
                                CONS-HIGH
                              </MenuItem>
                              <MenuItem key='COM-LOW' value='COM-LOW'>
                                COM-LOW
                              </MenuItem>
                              <MenuItem key='COM-MID' value='COM-MID'>
                                COM-MID
                              </MenuItem>
                              <MenuItem key='COM-HIGH' value='COM-HIGH'>
                                COM-HIGH
                              </MenuItem>
                            </TextField>                        </TableCell>
                        <TableCell align="left">
                        <Input type="text" required="true" value={articlePriceEdit} onChange={(e) => setArticlePriceEdit(e.target.value)} />
                        </TableCell>

                        <TableCell align="left">
                            <Iconify
                              icon="eva:save-outline"
                              width={20}
                              height={20}
                              color="darkBlue"
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                handleUpdate();
                              }}
                            />
                            <Iconify
                              icon="eva:close-circle-outline"
                              width={20}
                              height={20}
                              color="red"
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                canselEditRow();
                              }}
                            />
                          </TableCell>

                        {/* <TableCell align="right">
                          <UserMoreMenu />
                        </TableCell> */}
                      </TableRow>
                      )
                    }

                    else{
                    return (
                      <TableRow
                        hover
                        key={_id}
                        tabIndex={-1}
                        email="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, _id)} />
                        </TableCell>
                        <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            {/* <Avatar alt={name} src={avatarUrl} /> */}
                            <Typography variant="subtitle2" noWrap>
                            {reference}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align="left">{description}</TableCell>
                        <TableCell align="left">{classification}</TableCell>
                        <TableCell align="left">{currentPrice}</TableCell>

                        <TableCell align="left">
                          <Iconify
                                    icon="eva:edit-2-outline"
                                    width={20}
                                    height={20}
                                    color="gray"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                      editRow(row);
                                    }}
                                  />
                                  <Iconify
                                    icon="eva:trash-2-outline"
                                    width={20}
                                    height={20}
                                    color="gray"
                                    style={{ cursor: 'pointer' }}
                                    onClick={e => handleOpenPopupDeleteArticle(row)}
                                  />
                          </TableCell>

                        {/* <TableCell align="right">
                          <UserMoreMenu />
                        </TableCell> */}
                      </TableRow>
                    )};
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[10, 20, 30]}
            component="div"
            count={filteredArticles.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
        <AddArticleForm
        showAddArticleForm={showAddArticleForm}
        setShowAddArticleForm={setShowAddArticleForm}
        getArticles= {getArticles}
      />
      <AlertConfirmDeleteArticle
          openPopupDeleteArticle={openPopupDeleteArticle}
          setOpenPopupDeleteArticle={setOpenPopupDeleteArticle}
          articleToDelete={articleToDelete}
          getArticles={getArticles}
        />
      <AlertConfirmDeleteArticles
          openPopupDeleteArticles={openPopupDeleteArticles}
          setOpenPopupDeleteArticles={setOpenPopupDeleteArticles}
          selectedArticles={selected}
          getArticles={getArticles}
          setSelected={setSelected}
        />
      </Container>
    </Page>
  );
}
